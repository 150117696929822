import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";

//context
import DonorsContext from 'context/DonorsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getDonor from 'services/getDonor';
import updateDonor from 'services/updateDonor';
import deleteDonation from 'services/deleteDonation';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
  Modal,
  Card,
  Container,
  Collapse,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import AddOfflineForm from "views/pages/forms/AddOfflineForm.js";
import { ArrowRight, CaretDownFill, Coin, PlusLg } from 'react-bootstrap-icons';
import AddLotteryDonation from './AddLotteryDonation';

function EditDonorCard({ donor, teamsOptions1, teamsOptions3, callget }) {
  const { id, name, phone, phone2, phone3, phone4, address, email, old_hok, team3, campaign, team1, terminal, apt_id, first_name, last_name, note, status, status2, status3 } = donor
  const { user: currentUser } = useContext(UserContext);
  const [formValues, setFormValues] = useState({
    id: id,
    name: name,
    phone: phone,
    phone2: phone2,
    phone3: phone3,
    phone4: phone4,
    address: address,
    old_hok: old_hok,
    campaign: campaign,
    team1: team1,
    team3: team3,
    terminal: terminal,
    apt_id: apt_id,
    first_name: first_name,
    last_name: last_name,
    note: note,
    status: status,
    status2: status2,
    status3: status3,
    email: email,
  });
  const [pending, setPending] = useState(false);
  const [edit, setEdit] = useState(currentUser.campaign.permission < 25);
  const [sent, setSent] = useState(false);
  const { donors, setDonors } = useContext(DonorsContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [formModal, setformModal] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const statusOptions = [
    { status: '(ריק)', value: '' },
    { status: 'תרם', value: 'Donated' },
    { status: 'לא רלוונטי', value: 'לא רלוונטי' },
  ];

  function modalCallback(status) {
    setformModal(status)
  }

  function textToAarray(text) {
    return text.split(",");
  }

  useEffect(() => {
    setFormValues({
      id: id,
      name: name,
      phone: phone,
      address: address,
      old_hok: old_hok,
      campaign: campaign,
      team1: team1,
      team3: team3,
      terminal: terminal,
      apt_id: apt_id,
      first_name: first_name,
      last_name: last_name,
      note: note,
      status: status,
      status2: status2,
      status3: status3,
      email: email,
    });
  }, [donor])

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      ['name']: formValues.last_name + " " + formValues.first_name
    });
  }, [formValues.last_name, formValues.first_name])

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonor();
  }

  function removeDonor() {
    setPending(true);
    deleteDonation(formValues.id)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            /* setSent(true); */
            /* setformModal(false); */
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function setDonor() {
    setPending(true);
    updateDonor(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            /* setSent(true); */
            callget(250, 0);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        backdrop={'static'}
        toggle={() => setformModal(false)}
      >
      <div className="modal-body p-0">
        <Card className="bg-secondary border-0">
          <AddLotteryDonation
            donor={donor}
            modalCallback={modalCallback}
            callget={callget}
          />
        </Card>
      </div>
    </Modal>
      { pending && <Pending /> }
      <Row className="text-center p-4">
        <Col className="text-right">
          <Link to={`/donors`} className="text-dark">
            <ArrowRight size={19} className="m-0" /> חזרה לתורמים
          </Link>
        </Col>
        <Col className="">
          <h1>תורם: {name} (מס' {id})</h1>
        </Col>
        <Col className='text-left' >
          <Button
            className="btn-donors-blue mr-3"
            size="md"
            onClick={() => setformModal(true)}
          >
            <Coin className="ml-2" size={20}
            />
            הוספת תרומה
          </Button>
        </Col>
      </Row>
      <Card>
        <CardBody className='bg-white mb-3'>
          <Form role="form" onSubmit={handleSubmit}>
            <Row>
              <Col md="3">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="first_name"
                  >
                    שם פרטי
                  </label>
                  <Input
                    id="first_name"
                    type="text"
                    name="first_name"
                    placeholder="שם פרטי"
                    onChange={handleChange}
                    onBlur={handleChange}
                    required
                    value={formValues.first_name}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="last_name"
                  >
                    שם משפחה
                  </label>
                  <Input
                    id="last_name"
                    type="text"
                    name="last_name"
                    placeholder="שם משפחה"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.last_name}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="phone"
                  >
                    פלאפון
                  </label>
                  <Input
                    id="phone"
                    type="phone"
                    name="phone"
                    placeholder="מס טל' / פלא'"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.phone}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="address"
                  >
                    כתובת
                  </label>
                  <Input
                    id="address"
                    type="text"
                    name="address"
                    placeholder="כתובת"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.address}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="city"
                  >
                    עיר
                  </label>
                  <Input
                    id="city"
                    type="text"
                    name="city"
                    placeholder="עיר"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.city}
                  />
                </FormGroup>
              </Col>
              <Col md="3" className='bg-lighter'>
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="status"
                  >
                    סטטוס
                  </label>
                  <Input
                    id="status"
                    type="select"
                    name="status"
                    placeholder="סטטוס"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.status}
                  >
                    {statusOptions.map((option) => (
                      <option value={option.value} key={option.status}>{option.status}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              {(currentUser.campaign.meta.status2_display && currentUser.campaign.meta.status2_display == 'true') &&
                <Col md="3" className='bg-lighter'>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label">
                      {currentUser.campaign.meta.status2_title}
                    </label>
                    <Input
                      id="status2"
                      type="select"
                      name="status2"
                      onChange={handleChange}
                      value={formValues.status2}
                    >
                      <option value="" key="nulls">(ריק)</option>
                      {textToAarray(currentUser.campaign.meta.status2_values)?.map((option) => (
                        <option value={option} key={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>}
              <Col md="3" className='bg-lighter'>
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="note"
                  >
                    הערה
                  </label>
                  <Input
                    id="note"
                    type="text"
                    name="note"
                    placeholder="הערה"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.note}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button onClick={() => { setCollapse(!collapse) }} className='mr-0 mb-3 text-right bg-light'>
              {!collapse ? <PlusLg /> : <CaretDownFill />} פרטים נוספים
            </Button>
            <Collapse isOpen={collapse}>
              <Row>
                <Col md="3" className='bg-lighter'>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team3"
                    >
                      נציג / קהילה
                    </label>
                    <Input
                      id="team3"
                      type="select"
                      name="team3"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.team3}
                    >
                      {teamsOptions3.map((option) => (
                        <option value={option.team} key={option.id}>{option.team}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="3" className='bg-lighter'>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team1"
                    >
                      מתרים
                    </label>
                    <Input
                      id="team1"
                      type="select"
                      name="team1"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.team1}
                    >
                      {teamsOptions1.map((option) => (
                        <option value={option.team} key={option.id}>{option.team}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="email"
                    >
                      אימייל
                    </label>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="אמייל"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.email}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone2"
                    >
                      פלאפון 2
                    </label>
                    <Input
                      id="phone2"
                      type="phone"
                      name="phone2"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone2}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone3"
                    >
                      פלאפון 3
                    </label>
                    <Input
                      id="phone3"
                      type="phone"
                      name="phone3"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone3}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone4"
                    >
                      פלאפון 4
                    </label>
                    <Input
                      id="phone4"
                      type="phone"
                      name="phone4"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone4}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="old_hok"
                    >
                      סכום הו"ק קודם
                    </label>
                    <Input
                      id="old_hok"
                      type="text"
                      name="old_hok"
                      placeholder="סכום הוק קודם"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.old_hok}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="apt_id"
                    >
                      מזהה apt
                    </label>
                    <Input
                      id="apt_id"
                      type="text"
                      name="apt_id"
                      placeholder="מזהה apt"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.apt_id}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
            <Button
              block
              color="info"
              className="bg-info"
              type="submit"
            >
              שמור שינויים
            </Button>
          </Form>
          {/*  <Form role="form" onSubmit={handleSubmit}>
            <Row>
              <Col md="3">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="amount"
                  >
                    שם מלא:
                  </label>
                  {!edit ? <span className='mr-3'>{formValues.name}</span> :
                    <Input
                      id="name"
                      type="text"
                      name="name"
                      placeholder="שם מלא"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.name}
                    />}
                </FormGroup>
              </Col>
              </Row>
          </Form> */}
        </CardBody>
      </Card>
    </>
  );
}

export default EditDonorCard;
