import React, { useState, useContext, useRef, useEffect } from 'react';
// react plugin that prints a given react component
//context
import { UserContext } from "context/userContext";
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getOrphanDonations from 'services/getOrphanDonations';
//react-to-print
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";
import { PersonCheck } from 'react-bootstrap-icons';
// core components
import MergeDonation from "views/pages/forms/MergeDonation.js";
import AddDonorMerge from "views/pages/forms/AddDonorMerge.js";


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        הצג {" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option key="10" value="10">10</option>
            <option key="25" value="25">25</option>
            <option key="50" value="50">50</option>
            <option key="100" value="100">100</option>
            <option key="250" value="250">250</option>
          </select>
        }{" "}
        שורות
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function DonationsMerge() {
  const [alert, setAlert] = useState(null);
  const componentRef = useRef(null);
  const { user } = useContext(UserContext);
  const [ donations, setDonations ] = useState([]);
  const { showAlert } = useContext(AlertMsgContext);
  const [formModal, setformModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [currentDonation, setCurrentDonation] = useState({});

  function callget() {
    getOrphanDonations(user.campaign.id)
      .then(
        (response) => {
          setDonations(response);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת עדכון', 'danger')
      }
      );
  }

  function modalCallback(status) {
    setformModal(status)
  }

  function modalAddCallback(status) {
    setAddModal(status)
  }
   
  useEffect(() => {
    callget();
    document.title = `donors - שיוך תרומות`;
  }, [])


  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="המידע הועתק ללוח!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        ניתן להדביק באקסל או בכל מקום
      </ReactBSAlert>
    );
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentDonation(row)
      setformModal(true)
    }
  };

  return (
    <>
      {user.type == 4 ?
        <div>
          <h1 className="text-center m-4">שיוך תרומות</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          {alert}
          <h1 className="text-center mt-3 mb-0 text-donors-pink"><PersonCheck size={23} className="mb-1" /> שיוך תרומות</h1>
          <h4 className="text-center mb-4">תרומות מ'נדרים' שלא זוהו במאגר התורמים</h4>
          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={addModal}
            toggle={() => setAddModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <AddDonorMerge
                  donation={currentDonation}
                  modalAddCallback = {modalAddCallback}
                  callget = {callget}
                />
              </Card>
            </div>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formModal}
            toggle={() => setformModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <MergeDonation
                  donation={currentDonation}
                  modalAddCallback = {modalAddCallback}
                  modalCallback = {modalCallback}
                  callget = {callget}
                />
              </Card>
            </div>
          </Modal>
          <Container fluid>
            <Row>
              <div className="col">
                <Card>
                  <ToolkitProvider
                    data={donations}
                    keyField="id"
                    columns={[
                      {
                        dataField: "id",
                        text: "מזהה",
                        sort: true,
                      },
                      {
                        dataField: "name",
                        text: "שם",
                        sort: true,
                      },
                       {
                        dataField: "phone",
                        text: "פלאפון",
                        sort: true,
                      },
                       {
                        dataField: "address",
                        text: "כתובת",
                        sort: true,
                      },
                      {
                        dataField: "amount",
                        text: "סכום",
                        sort: true,
                      },
                      {
                        dataField: "Tashloumim",
                        text: "תשלומים",
                        sort: true,
                      },
                      {
                        dataField: "terminal",
                        text: "מסוף",
                        sort: true,
                      },
                      {
                        dataField: "user",
                        text: "מעדכן",
                        sort: true,
                      },
                      {
                        dataField: "Comments",
                        text: "הערות",
                        sort: true,
                      },
                      {
                        dataField: "create_time",
                        text: "תאריך",
                        sort: true,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          <Row className="mb-3">
                            <Col xs={12} sm={4}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  חפש:
                                  <SearchBar
                                    className="form-control-md"
                                    placeholder="חיפוש חכם"
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          rowEvents={rowEvents}
                          rowClasses="hover-row-class"
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          id="react-bs-table"
                          defaultSorted={[{
                            dataField: 'id',
                            order: 'desc'
                          }]}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            </Row>
          </Container>
        </div>}
    </>
  );
}

export default DonationsMerge;
