export default function deleteTeam(id) {
    let url = `https://lottery.donors.co.il/wp-json/shpro/v1/teams/delete/${id}`;

    let headers = new Headers();
    headers.set('Accept', 'application/json');

    const options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}