import React, { useContext, useEffect, useState } from 'react';
//context
import { UserContext } from "context/userContext";
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getNewCampaign from 'services/getNewCampaign';
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  CardTitle,
  CardHeader,
  Table,
  Progress,
  Modal
} from "reactstrap";
//
import { Grid, Megaphone } from 'react-bootstrap-icons';
import AddCampaign from './pages/forms/AddCampaign';
import AddOrganization from './pages/forms/AddOrganization';

function Campaigns() {
  const { user, setUser } = useContext(UserContext);
  const [formAddModal, setFormAddModal] = useState(false);
  const [switchNow, setSwitchNow] = useState(0);
  const [newOrg, setNewOrg] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  function switchCampign(campaign) {
    setSwitchNow(campaign);
    getNewCampaign(campaign)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('אין הרשאה', 'danger');
            setSwitchNow(0);
          }
          else {
            setUser(data);
            setSwitchNow(0);
          }
        })
      .catch((err) => {
        showAlert('שגיאת תקשורת', 'danger')
        console.log('Error! c');
        setSwitchNow(0);
      }
      );
  }

  useEffect(() => {
    document.title = `donors - קמפיינים`;
  }, [])

  const cmpCol = () => {
    return user.campaigns?.map((prop) => {
      return (
        <Col md="6" xl="4">
          <Card className="p-2">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    className="text-donors-light mb-0"
                  >
                    קמפיין id: 57413{prop.campaign}
                  </CardTitle>
                  <h1 tag="h2" className="font-weight-bold mb-0 text-donors-blue">
                    {prop.campaign_name}
                  </h1>
                  {prop.campaign !== user.campaign.id ? <Button
                    size='sm'
                    color="light"
                    onClick={() => switchCampign(prop.campaign)}
                  >
                    {switchNow === prop.campaign ? 'החלפת קמפיין...' : "הצג"}
                  </Button> :
                    <Button
                      size='sm'
                      className='btn-donors-blue'
                    >
                      מוצג
                    </Button>
                  }
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-donors-g3 text-white rounded-circle shadow">
                    <Megaphone />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      );
    });
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formAddModal}
        toggle={() => setFormAddModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            {!newOrg ? <AddCampaign
              setNewOrg={setNewOrg}
              switchCampign={switchCampign}
              close={setFormAddModal}
            /> :
              <AddOrganization
                setNewOrg={setNewOrg}
              />}
          </Card>
        </div>
      </Modal>
      {(user.role < 3) &&
        <Button
          className="fixed-button btn-donors-pink"
          size="md"
          onClick={() => setFormAddModal(true)}
        >
          קמפיין חדש
        </Button>
      }
      <Container fluid>
        <h1 className="text-center mt-3 mb-0 text-donors-pink"><Grid size={22} className="" /> הקמפיינים שלי</h1>
        <div className="header-body">
          <Row className="mt-4 align-items-stretch">
            {cmpCol()}
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Campaigns;
