export default function postDonation(data) {
    let url = `https://lottery.donors.co.il/wp-json/shpro/v1/campaign/${data.campaign}/donation`;
    const token = localStorage.getItem("donors-jwt");
    data.jsonpackages = JSON.stringify(data.packages);
    /* data.jsonpackages = data.packages.map((prop, key) => {
        return JSON.stringify(prop)
      }) */

    let formData = new FormData();
    for (const name in data) {
        formData.append(name, data[name]);
    }

    let headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Authorization', 'Bearer ' + token);

    const options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers,
        body: formData
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}