import React, { useContext, useState } from 'react';
//context
import { UserContext } from "context/userContext";
import { CampaignContext } from "context/campaignContext";
import { AlertMsgContext } from "context/AlertMsgContext";
import DonorsContext from 'context/DonorsContext';
import DonationsContext from 'context/DonationsContext';
import TeamsContext from 'context/TeamsContext';
// nodejs library that concatenates classes
import classnames from "classnames";
// react library for routing
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavbarBrand,
  NavItem,
  Nav,
  Container,
  Input,
} from "reactstrap";
import { BoxArrowRight, } from 'react-bootstrap-icons';
//services
import getNewCampaign from 'services/getNewCampaign';

function AdminNavbar({ logo, theme, sidenavOpen, toggleSidenav }) {
  const { user, setUser } = useContext(UserContext);
  const { setDonors } = useContext(DonorsContext);
  const { setCampaign } = useContext(CampaignContext);
  const { setDonations } = useContext(DonationsContext);
  const { setTeams } = useContext(TeamsContext);
  const { displayName: userFullname } = user;
  const [profileIsOpen, setProfileIsOpen] = useState(false);
  const [switchNow, setSwitchNow] = useState(0);
  const { showAlert } = useContext(AlertMsgContext);

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  function loguot() {
    localStorage.removeItem("donors-jwt");
    localStorage.removeItem("userId");
    localStorage.removeItem("campaignId");
    localStorage.removeItem("userType");
    setUser('');
    setDonors([]);
    setDonations([]);
    setTeams([]);
    setCampaign({});
  }

  function switchCampign(e) {
    const campaign = e.target.value;
    setSwitchNow(campaign);
    getNewCampaign(campaign)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('אין הרשאה', 'danger');
            setSwitchNow(0);
          }
          else {
            setUser(data);
            setSwitchNow(0);
            window.location.reload(false);
          }
        })
      .catch((err) => {
        showAlert('שגיאת תקשורת', 'danger')
        console.log('Error! c');
        setSwitchNow(0);
      }
      );
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-expand",
          { "navbar-dark bg-achim-pink": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <select className="select-campaign p-1 rounded bg-achim-pink text-light"
            onChange={switchCampign}
            value={user.campaign.id}
          >
            {user.campaigns.map((option) => (
              <option value={option.campaign} key={option.campaign}>{option.campaign_name}</option>
            ))}
          </select>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto pr-0" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-0 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <NavbarBrand {...navbarBrandProps}
              className="nav-logo"
            >
              <img
                alt={logo.imgAlt}
                src={logo.imgSrc}
              />
            </NavbarBrand>
            <Nav className="align-items-center mr-auto mr-md-0" navbar>
              <UncontrolledDropdown
                nav
                isOpen={profileIsOpen}
                onMouseOver={() => setProfileIsOpen(true)}
                onClick={() => setProfileIsOpen(true)}
                onMouseLeave={() => setProfileIsOpen(false)}
                toggle={() => setProfileIsOpen(!profileIsOpen)}
              >
                <DropdownToggle
                  className="nav-link pr-0" color="" tag="a"
                >
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/user-01.jpg").default}
                      />
                    </span>
                    <Media className="mr-2 d-none d-lg-block">
                      {user && <span className="mb-0 text-sm font-weight-bold">
                        {userFullname}
                      </span>}
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h5 className="text-overflow m-0">שלום {userFullname}</h5>
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={loguot}
                  >
                    <i><BoxArrowRight size={26} className="mb-1" /></i>
                    <span className="nav-link-text mr-2">יציאה</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
