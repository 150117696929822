import React, { useState, useContext, useEffect } from 'react';
//context
import { CampaignContext } from "context/campaignContext";
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import postDonation from 'services/postDonation';
import deleteDonation from 'services/deleteDonation';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
  ButtonGroup,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import { DashCircle, PlusCircle } from 'react-bootstrap-icons';
import classnames from "classnames";
import NedarimIframe from './NedarimIframe';


function AddLotteryDonation({ donor, modalCallback, callget, donation, type }) {
  const { name: fullName, id, phone, address, email } = donor
  const { user } = useContext(UserContext);
  const { campaign } = useContext(CampaignContext);
  const { campaign_type } = campaign;
  const [formValues, setFormValues] = useState({
    donation: 0,
    donor: id,
    fullName: fullName,
    phone: phone,
    address: address,
    email: email,
    amount: 0,
    sum: 0,
    tickets: 0,
    terminal: 'דשבורד',
    user: user.displayName,
    campaign: user.campaign.id,
    insight: true,
    Comments: '',
    Tashloumim: 12,
    Zeout: '',
    methods_Payment: 'אשראי',
    approved: 0,
    packages: [],
    paymentType: 'Ragil',
    double: true
  });
  const [packages, setPackages] = useState([]);
  const [double, setDouble] = useState(true);
  const [step, setStep] = useState(0);
  const [close, setClose] = useState(true);
  const [pending, setPending] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    //if (name === 'Tashloumim' && (value < 11 || value > 999)) return;
    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleChangeHK = (e) => {
    setFormValues({
      ...formValues,
      ['paymentType']: formValues.paymentType === 'Ragil' ? 'HK' : 'Ragil'
    });
  }

  function separator(numb) {
    if (!numb) return numb;
    let str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }

  const plusMonth = (e) => {
    let value = (formValues.Tashloumim * 1) + 1;
    if (value < 12) value = 12;
    setFormValues({
      ...formValues,
      ['Tashloumim']: value
    });
  }

  function handleChangePackages(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    const myNextList = [...packages];
    const artwork = myNextList.find(
      a => a.id === name
    );
    artwork.amount = value;
    setPackages(myNextList);
  }

  function handlePlusPackages(id, plus) {
    const myNextList = [...packages];
    const artwork = myNextList.find(
      a => a.id === id
    );
    const amount = artwork.amount * 1;
    if (plus) {
      amount + 1 < 100 && (artwork.amount = amount + 1);
    } else {
      amount - 1 > -1 && (artwork.amount = amount - 1);
    }

    setPackages(myNextList);
  }

  useEffect(() => {
    const sum = packages.reduce((accumulator, object) => {
      return accumulator + (object.price * object.amount);
    }, 0);
    let tickets = packages.reduce((accumulator, object) => {
      return accumulator + (object.tickets * object.amount);
    }, 0);
    if (double) tickets = tickets * 2;
    const filterPackages = packages.filter(function (p) {
      return p.amount > 0 && p.gift;
    });
    if (formValues.Tashloumim === '') {
      setFormValues({
        ...formValues,
        ['amount']: sum * 1,
        ['sum']: sum * formValues.Tashloumim,
        ['tickets']: tickets,
        ['packages']: filterPackages,
        ['paymentType']: 'HK',
        ['double']: double
      });
    }
    else if (formValues.Tashloumim < 13) {
      setFormValues({
        ...formValues,
        ['amount']: sum * 1,
        ['sum']: sum * formValues.Tashloumim,
        ['tickets']: tickets,
        ['packages']: filterPackages,
        ['double']: double,
        ['paymentType']: 'Ragil'
      });
    }
    else {
      setFormValues({
        ...formValues,
        ['amount']: sum * 1,
        ['sum']: sum * formValues.Tashloumim,
        ['tickets']: tickets,
        ['double']: double,
        ['packages']: filterPackages
      });
    }
  }, [packages, double, formValues.Tashloumim])

  useEffect(() => {
    setFormValues({
      ...formValues,
      ['approved']: formValues.methods_Payment === 'אשראי' ? 0 : 1,
    });
  }, [formValues.methods_Payment])

  const handleSubmit = async (e) => {
    e.preventDefault();

    setDonation();
    /* if (formValues.methods_Payment === 'אשראי') {
      //setNedarim();
      setStep(step + 1)
    } else {
      setDonation();
    } */
  }

  function setDonation() {
    setClose(false);
    setPending(true);
    postDonation(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
            setClose(true);
          }
          else {
            setPending(false);
            if (formValues.methods_Payment === 'אשראי') {
              setFormValues({
                ...formValues,
                ['donation']: data,
              });
              setStep(step + 1);
            } else {
              modalCallback(false);
              callget(0, 'restart');
            }
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
        setClose(true);
      }
      );
  }

  function deleteDonations() {
    setStep(step - 1);
    setClose(true);
    deleteDonation(formValues.donation)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
      }
      );
  }

  useEffect(() => {
    let packagesN = user.campaign.packages;

    const packagesB = packagesN.map((prop, key) => {
      return ({ ...prop, amount: 0 })
    })
    setPackages(packagesB);

    if (type === 'edit'){
       setFormValues({
        donation: donation.id,
        donor: donation.donor,
        fullName: donation.name,
        phone: donation.phone,
        address: donation.address,
        email: donation.email,
        amount: donation.amount,
        sum: donation.amount,
        tickets: donation.KevaId,
        terminal: donation.terminal,
        user: donation.user,
        campaign: donation.campaign,
        insight: true,
        Comments: donation.Comments,
        Tashloumim: donation.Tashloumim,
        Zeout: donation.Zeout,
        methods_Payment: donation.methods_Payment,
        approved: donation.approved,
        packages: [],
        paymentType: donation.KabalaId,
        double: donation.Groupe,
      });
    }
  }, [])

  const createPackages = () => {
    return user.campaign.packages.map((prop, key) => {
      return (
        <Row key={key}
          className={classnames(
            "border-light w-100 border-bottom p-3 m-0",
            (key % 2 == 0) ? 'bg-lighter' : ''
          )}
        >
          <Col className='p-0'>
            <label
              className="form-control-label p-0 m-0"
              htmlFor={key + "amount"}
            >
              {prop.name} {" | "}
              {prop.price} ₪ {" | "}
              {prop.tickets} {prop.tickets > 1 ? ' כרטיסים' : ' כרטיס'}
              {prop.gift && " | " + '+ ' + prop.gift}
            </label>
          </Col>
          <Col className='p-0 w-100 text-left'>
            <FormGroup className='d-inline'>
              <Button
                color="primary" type="button" className='px-2 d-inline'
                onClick={() => handlePlusPackages(packages[key].id, false)}
                disabled={type === 'edit'}
              >
                <DashCircle size={20} />
              </Button>
              <Input
                id={key + "amount"}
                type="text"
                disabled
                name={packages[key].id}
                className='w-25 d-inline bg-white text-center'
                placeholder="כמות"
                onChange={handleChangePackages}
                onBlur={handleChangePackages}
                value={packages[key].amount}
                min={0}
                max={100}
              />
              <Button
                color="success" type="button" className='px-2 d-inline'
                onClick={() => handlePlusPackages(packages[key].id, true)}
                disabled={type === 'edit'}
              >
                <PlusCircle size={20} />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      )
    })
  }

  return (
    <>
      {pending && <Pending />}
      <CardHeader className="text-center bg-lighter p-2">
        {!close || <button className="close p-2" onClick={() => modalCallback(false)} type="button">
          &times;
        </button>
        }
        <h1 className='m-0'>{type === 'edit' ? 'עריכת' : 'הוספת'} תרומה</h1>
        <h4>לתורם {type === 'edit' ? formValues.fullName : fullName} - {type === 'edit' ? formValues.donor : id}</h4>
      </CardHeader>
      <CardBody>
        <Form role="form" onSubmit={handleSubmit}>
          {{
            0:
              <>
                {packages.length && createPackages()}
                <Row className='w-100 text-right pt-3'>
                  <Col>
                    <FormGroup className="mb-3">
                      <Input
                        id="double"
                        type="checkbox"
                        name="double"
                        checked={double}
                        disabled={type === 'edit'}
                        onChange={() => { setDouble((double) => !double) }}
                      />
                      <label
                        className="form-control-label mr-3"
                        htmlFor="amount"
                      >
                        הכפלה

                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='w-100 text-center d-block pb-3'>
                  <h2 className='m-0'>סה"כ: {formValues.amount} ₪ לחודש<span className='text-red'> | </span> {formValues.tickets} כרטיסי הגרלה</h2>
                </Row>
                <Row className='w-100 text-center p-0 m-0'>
                  {/*  <Col className='p-1 m-0'>
                    <Button
                      block
                      color={'light'}
                      type="submit"
                      disabled
                    >
                      {'< '} פרטי תורם
                    </Button>
                  </Col> */}
                  <Col className='p-1 m-0'>
                    <Button
                      block
                      color={'light'}
                      onClick={() => modalCallback(false)}
                    >
                      ביטול
                    </Button>
                  </Col>
                  <Col className='p-1 m-0'>
                    <Button
                      onClick={() => setStep(step + 1)}
                      disabled={!formValues.amount > 0}
                      block
                      color={'info'}
                      type="submit"
                    >
                      המשך {' >'}
                    </Button>
                  </Col>
                </Row>
              </>
            , 1:
              <>
                <Row className='w-100 text-center d-block m-0 pb-3'>
                  <h2 className='m-0 text-info'>
                    {formValues.tickets} כרטיסי הגרלה
                  </h2>
                  {!formValues.packages.length ||

                    formValues.packages.map((prop, key) => {
                      return (
                        <span key={key}
                        >
                          + {prop.gift}{' '}
                        </span>
                      )
                    })
                  }
                  <h2 className='m-0 mb-2'><span className='text-red'>{separator(formValues.amount)} ₪</span> לחודש
                  </h2>
                  <ButtonGroup className="btn-group-toggle bg-light w-100" data-toggle="buttons">
                    <Button className={classnames({ active: formValues.Tashloumim == 12 }, 'm-2')}
                      color="secondary" onClick={handleChange}
                      name='Tashloumim'
                      value={12}
                      disabled={type === 'edit'}
                    >
                      <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={formValues.Tashloumim == 12}
                      />
                      12
                    </Button>
                    <Button className={classnames({ active: formValues.Tashloumim == 24 }, 'm-2')}
                      color="secondary" onClick={handleChange}
                      name='Tashloumim'
                      value={24}
                      disabled={type === 'edit'}
                    >
                      <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={formValues.Tashloumim == 24}
                      />
                      24
                    </Button>
                    <Button className={classnames({ active: formValues.Tashloumim == 36 }, 'm-2')}
                      color="secondary" onClick={handleChange}
                      name='Tashloumim'
                      value={36}
                      disabled={type === 'edit'}
                    >
                      <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={formValues.Tashloumim == 36}
                      />
                      36
                    </Button>
                    <Button className={classnames({ active: formValues.Tashloumim == '' }, 'm-2')}
                      color="secondary" onClick={handleChange}
                      name='Tashloumim'
                      value={''}
                      disabled={type === 'edit'}
                    >
                      <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={formValues.Tashloumim == ''}
                      />
                      ללא הגבלה
                    </Button>
                    <Button className={classnames({ active: true }, 'm-2 bg-green')}
                      color="success" onClick={plusMonth}
                      disabled={type === 'edit'}
                    >
                      <PlusCircle size={20} />
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className='bg-light w-100 text-center'>
                    <h2 className='m-0 mb-2 text-center w-100'>
                      {formValues.Tashloumim === '' ?
                        ' ללא הגבלה'
                        :
                        <>
                          למשך <span className='text-red'>{formValues.Tashloumim}</span>  חודשים
                        </>
                      }
                    </h2>
                  </ButtonGroup>
                </Row>
                <Row className='w-100 text-center d-block m-0 pb-3'>
                  {formValues.Tashloumim === '' ||
                    <h3 className='m-0 mb-2'>סה"כ: {' '}
                      {separator(formValues.sum)} ₪
                    </h3>
                  }
                </Row>
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="methods_Payment"
                  >
                    אמצעי תשלום:
                  </label>
                  {user.permission == 3 ?
                    <Input
                      id="methods_Payment"
                      type="select"
                      name="methods_Payment"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      disabled
                      value={formValues.methods_Payment}
                    >
                      <option key="2">אשראי</option>
                    </Input>
                    :
                    <Input
                      id="methods_Payment"
                      type="select"
                      name="methods_Payment"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.methods_Payment}
                      disabled={type === 'edit'}
                    >
                      {campaign_type === 'HK' ?
                        <>
                          <option key="2">אשראי</option>
                          <option key="8">אשראי חיצוני</option>
                          <option key="3">הוק בנקאי</option>
                          <option key="4">העלאת הוק בנקאי קיים</option>
                          <option key="5">העלאת הוק אשראי קיים</option>
                          <option key="6" >מזומן</option>
                          <option key="7" >אחר</option>
                        </> :
                        <>
                          <option key="2">מזומן</option>
                          <option key="3">שיק</option>
                          <option key="4">העברה בנקאית</option>
                          <option key="5">אשראי</option>
                          <option key="6">אשראי במסופון</option>
                          <option key="7" >אשראי קיים</option>
                        </>
                      }
                    </Input>}
                </FormGroup>
                {formValues.methods_Payment === 'אשראי' &&
                  <Row className='w-100 text-right p-0'>
                    <Col>
                      <FormGroup className="m-0">
                        <Input
                          id="double"
                          type="checkbox"
                          name="double"
                          checked={formValues.paymentType === 'Ragil'}
                          disabled={formValues.Tashloumim < 13}
                          onChange={handleChangeHK}
                        />
                        <label
                          className={classnames(
                            "form-control-label mr-3",
                            formValues.Tashloumim < 13 && 'text-light'
                          )}
                          htmlFor="double"
                        >
                          תפיסת מסגרת
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="Comments"
                      >
                        הערות
                      </label>
                      <Input
                        id="Comments"
                        rows="1"
                        type="textarea"
                        name="Comments"
                        placeholder="הערות לתרומה"
                        onChange={handleChange}
                        onBlur={handleChange}
                        disabled={type === 'edit'}
                        value={formValues.Comments}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='w-100 text-center p-0 m-0'>
                  <Col className='p-1 m-0'>
                    <Button
                      block
                      color={'light'}
                      type="submit"
                      onClick={() => setStep(step - 1)}
                    >
                      {'< '} בחירת חבילות
                    </Button>
                  </Col>
                  <Col className='p-1 m-0'>
                    <Button
                      block
                      color={formValues.methods_Payment === 'אשראי' ? 'info' : 'success'}
                      type="submit"
                      disabled={type === 'edit'}
                    >
                      {formValues.methods_Payment === 'אשראי' ? 'פרטי אשראי' : 'הוסף תרומה'}
                      {' >'}
                    </Button>
                  </Col>
                </Row>
              </>
            , 2:
              <>
                <Row className='w-100 text-center d-block m-0 p-0'>
                  <h2 className='m-0 p-0'><span className='text-red'>{separator(formValues.amount)} ₪</span> לחודש
                    {formValues.Tashloumim === '' ?
                      ' ללא הגבלה'
                      :
                      <>
                        {' '} למשך <span className='text-red'>{formValues.Tashloumim}</span>  חודשים
                      </>
                    }
                  </h2>
                </Row>
                <NedarimIframe
                  props={formValues} />
                <Row className='w-100 text-center p-0 m-0'>
                  <Col className='p-1 m-0'>
                    <Button
                      block
                      outline
                      color={'warning'}
                      type="submit"
                      onClick={deleteDonations}
                    >
                      {'< '} ביטול תרומה
                    </Button>
                  </Col>
                </Row>
              </>
          }[step]}
        </Form>
      </CardBody>
    </>
  );
}

export default AddLotteryDonation;
