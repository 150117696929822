import React, { useState, useContext, useEffect } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import postDonor from 'services/postDonor';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
  Collapse,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import { CaretDownFill, PlusLg } from 'react-bootstrap-icons';

function AddDonor({ modalCallback, callget, teamsOptions3, teamsOptions1, donateModalAfterAddDonor }) {
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    phone2: '',
    phone3: '',
    phone4: '',
    address: '',
    city: '',
    old_hok: '',
    campaign: user.campaign.id,
    team1: '',
    team3: '',
    terminal: user.displayName,
    apt_id: '',
    first_name: '',
    last_name: '',
    note: '',
    email: ''
  });
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);
  const statusOptions = [
    { status: '(ריק)', value: '' },
    { status: 'תרם', value: 'Donated' },
    { status: 'לא רלוונטי',value: 'לא רלוונטי' },
  ];

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      ['name']: formValues.last_name + " " + formValues.first_name
    });
  }, [formValues.last_name, formValues.first_name])

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonor();
  }

  function setDonor() {
    setPending(true);
    postDonor(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            /* setSent(true); */
            modalCallback(false);
            donateModalAfterAddDonor(data);
            callget(0, 'restart');
          }
        })
      .catch((err) => {
        showAlert('שגיאת תקשורת', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      {user.campaign.permission > 15 ?
        <div>
          <h1 className="text-center m-4">הוספת תורם</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          <CardHeader className="text-center bg-lighter">
            <h1>הוספת תורם</h1>
          </CardHeader>
          <CardBody>
            <Form role="form" onSubmit={handleSubmit}>
              <Row>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="first_name"
                    >
                      שם פרטי
                    </label>
                    <Input
                      id="first_name"
                      type="text"
                      name="first_name"
                      placeholder="שם פרטי"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.first_name}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="last_name"
                    >
                      שם משפחה
                    </label>
                    <Input
                      id="last_name"
                      type="text"
                      name="last_name"
                      placeholder="שם משפחה"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.last_name}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone"
                    >
                      פלאפון
                    </label>
                    <Input
                      id="phone"
                      type="phone"
                      name="phone"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="address"
                    >
                      כתובת
                    </label>
                    <Input
                      id="address"
                      type="text"
                      name="address"
                      placeholder="כתובת"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.address}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="city"
                    >
                      עיר
                    </label>
                    <Input
                      id="city"
                      type="text"
                      name="city"
                      placeholder="עיר"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.city}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="email"
                    >
                      אימייל
                    </label>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="אמייל"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.email}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button  onClick={() => { setCollapse(!collapse) }} className='mr-0 mb-3 text-right bg-light'>
                {!collapse ? <PlusLg /> : <CaretDownFill />} פרטים נוספים
              </Button>
              <Collapse isOpen={collapse}>
              <Row>
              <Col md="4" className='bg-lighter'>
              <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team3"
                    >
                      נציג / קהילה
                    </label>
                    <Input
                      id="team3"
                      type="select"
                      name="team3"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.team3}
                    >
                      {teamsOptions3.map((option) => (
                        <option value={option.team} key={option.id}>{option.team}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4" className='bg-lighter'>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team1"
                    >
                      מתרים
                    </label>
                    <Input
                      id="team1"
                      type="select"
                      name="team1"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.team1}
                    >
                      {teamsOptions1.map((option) => (
                        <option value={option.team} key={option.id}>{option.team}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4" className='bg-lighter'>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="status"
                    >
                      סטטוס
                    </label>
                    <Input
                      id="status"
                      type="select"
                      name="status"
                      placeholder="סטטוס"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.status}
                    >
                      {statusOptions.map((option) => (
                        <option value={option.value} key={option.status}>{option.status}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone2"
                    >
                      פלאפון 2
                    </label>
                    <Input
                      id="phone2"
                      type="phone"
                      name="phone2"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone2}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone3"
                    >
                      פלאפון 3
                    </label>
                    <Input
                      id="phone3"
                      type="phone"
                      name="phone3"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone3}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone4"
                    >
                      פלאפון 4
                    </label>
                    <Input
                      id="phone4"
                      type="phone"
                      name="phone4"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone4}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="old_hok"
                    >
                      סכום הו"ק קודם
                    </label>
                    <Input
                      id="old_hok"
                      type="text"
                      name="old_hok"
                      placeholder="סכום הוק קודם"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.old_hok}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="apt_id"
                    >
                      מזהה apt
                    </label>
                    <Input
                      id="apt_id"
                      type="text"
                      name="apt_id"
                      placeholder="מזהה apt"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.apt_id}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="note"
                    >
                      הערה
                    </label>
                    <Input
                      id="note"
                      type="text"
                      name="note"
                      placeholder="הערה"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.note}
                    />
                  </FormGroup>
                </Col>
              </Row>
              </Collapse>
              <Button
                block
                color="info"
                className="bg-info"
                type="submit"
              >
                הוסף תורם
              </Button>
            </Form>
          </CardBody>
        </div>}
    </>
  );
}

export default AddDonor;
