export default function getTeamDonations(team, type, campaign) {
    let url = 'https://lottery.donors.co.il/wp-json/shpro/v1/donations/campaign/'+ campaign + '/type/' + type + '?team=' + team;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}


