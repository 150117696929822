export default function getDonors(campaign, limit, offset, filters, order, orderdir) {
    let params = '?';
    
    for (let k in filters) { params += `${k}=${filters[k]}&`; };
    params += `&limit=${limit}`;
    params += `&offset=${offset}`;
    params += `&order=${order}`;
    params += `&orderdir=${orderdir}`;
    
    let url = 'https://lottery.donors.co.il/wp-json/donors/v1/donors/campaign/' + campaign + params;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}


