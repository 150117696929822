import React, { useState, useContext, useEffect } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import update_campaign from 'services/update_campaign';
//context
import { UserContext } from "context/userContext";
import { CampaignContext } from "context/campaignContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
  UncontrolledCollapse,
  Collapse,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import { CaretDownFill, CaretLeftFill, CaretUpFill, ChevronDown, Megaphone } from 'react-bootstrap-icons';
import updateCampaignField from 'services/updateCampaignField';

function Campaign() {
  const { user } = useContext(UserContext);
  const { campaign, setCampaign } = useContext(CampaignContext);
  const [prewValue, setPrewValue] = useState('');
  const [collapse, setCollapse] = useState(false);
  const [collapse2, setCollapse2] = useState(false);

  function arrayToObject(arr, key) {
    return arr.reduce((obj, item) => {
      obj[item[key]] = item.meta_value
      return obj
    }, {})
  }

  const [pending, setPending] = useState(false);
  const { showAlert } = useContext(AlertMsgContext)

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setCampaign({
      ...campaign,
      [name]: value
    });
  }

  const handleChangeMeta = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setCampaign({
      ...campaign,
      meta: {
        ...campaign.meta,
        [name]: value

      }
    });
  }

  const handleFocus = (e) => {
    const target = e.target;
    const value = target.value;

    setPrewValue(value);
  }

  const handleSubmitField = async (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const placeholder = target.placeholder;
    if (prewValue !== value) {
      setField(name, value, placeholder);
    }
  }

  const handleSubmitMetaField = async (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const placeholder = target.placeholder;
    if (prewValue !== value) {
      setField(name, value, placeholder, true);
    }
  }

  function setField(name, value, placeholder, meta) {
    updateCampaignField(campaign.id, name, value, meta)
      .then(
        (response) => {
          const { success } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger');
            setCampaign({
              ...campaign,
              [name]: prewValue
            });
          }
          else {
            showAlert(placeholder + ' עודכן', 'success');
            if (!meta) {
              setCampaign({
                ...campaign,
                [name]: value
              });
            } else {

            }
          }
        })
      .catch((err) => {
        showAlert('בעיית תקשורת', 'danger');
        setCampaign({
          ...campaign,
          [name]: prewValue
        });
      }
      );
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setDonor();
  }

  function setDonor() {
    setPending(true);
    update_campaign(campaign)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל, נסה/י שוב ' + data, 'danger')
            setPending(false);
          }
          else {
            setPending(false);
          }
        })
      .catch((err) => {
        showAlert('בעיית תקשורת, תיכף נשוב', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  useEffect(() => {
    document.title = `donors - קמפיין`;
  }, [])

  const roles = ['administrator', 'manager','agent', 'leader' ];

  return (
    <>
      {pending && <Pending />}
      {!roles.includes(user.campaign.role) && user.campaign.permission > 20 ?
        <div>
          <h1 className="text-center m-4">עריכת קמפיין</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          <CardHeader className="text-center">
            <h1 className="text-center mt-3 mb-0 text-donors-pink"><Megaphone size={23} className="mb-1" /> עריכת קמפיין: {campaign.campaign}</h1>
            <h4 className="text-center m-2 text-red">זהירות! שינוי לא מבוקר עלול לפגוע בקמפיין</h4>
          </CardHeader>
          <Row className='justify-content-center m-0'>
            <Col md="8">
              <CardBody>
                <Form role="form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="campaign"
                        >
                          שם הקמפיין
                        </label>
                        <Input
                          id="campaign"
                          type="text"
                          name="campaign"
                          placeholder="שם הקמפיין"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          required
                          value={campaign.campaign}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="goal"
                        >
                          יעד כספי
                        </label>
                        <Input
                          id="goal"
                          type="number"
                          name="goal"
                          required
                          placeholder="יעד כספי"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.goal}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="donorsGoal"
                        >
                          יעד תורמים
                        </label>
                        <Input
                          id="donorsGoal"
                          type="text"
                          name="donorsGoal"
                          required
                          placeholder="יעד תורמים"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.donorsGoal}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="start_time"
                        >
                          תאריך התחלה
                        </label>
                        <Input
                          id="start_time"
                          type="text"
                          name="start_time"
                          placeholder="תאריך התחלה"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.start_time}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="end_time"
                        >
                          תאריך סיום
                        </label>
                        <Input
                          id="end_time"
                          type="text"
                          name="end_time"
                          placeholder="תאריך סיום"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.end_time}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="show_timer"
                        >
                          הצג שעון
                        </label>
                        <Input
                          id="show_timer"
                          type="select"
                          name="show_timer"
                          placeholder="הצג שעון"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.show_timer}
                        >
                          <option value='1' key={1}>הצג</option>
                          <option value='0' key={2}>אל תציג</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="show_campaign_goal"
                        >
                          הצג יעד קמפיין
                        </label>
                        <Input
                          id="show_campaign_goal"
                          type="select"
                          name="show_campaign_goal"
                          placeholder="הצג יעד קמפיין"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.show_campaign_goal}
                        >
                          <option value='1' key={1}>הצג</option>
                          <option value='0' key={2}>אל תציג</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="campaign_goal_type"
                        >
                          יעד הקמפיין
                        </label>
                        <Input
                          id="campaign_goal_type"
                          type="select"
                          name="campaign_goal_type"
                          placeholder="יעד הקמפיין"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.campaign_goal_type}
                        >
                          <option value='תורמים' key={1}>תורמים</option>
                          <option value='סכום' key={2}>סכום</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="matrimim_goal_type"
                        >
                          יעד מתרימים
                        </label>
                        <Input
                          id="matrimim_goal_type"
                          type="select"
                          name="matrimim_goal_type"
                          placeholder="יעד מתרימים"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.matrimim_goal_type}
                        >
                          <option value='תורמים' key={1}>תורמים</option>
                          <option value='סכום' key={2}>סכום</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="bars_goal_type"
                        >
                          יעד קהילות
                        </label>
                        <Input
                          id="bars_goal_type"
                          type="select"
                          name="bars_goal_type"
                          placeholder="יעד קהילות"
                          required
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleSubmitField}
                          value={campaign.bars_goal_type}
                        >
                          <option value='תורמים' key={1}>תורמים</option>
                          <option value='סכום' key={2}>סכום</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button block onClick={() => { setCollapse2(!collapse2) }} className='mr-0 mb-3 text-right bg-lighter'>
                    {!collapse2 ? <CaretLeftFill /> : <CaretDownFill />} הגדרות מערכת
                  </Button>
                  <Collapse isOpen={collapse2}>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="campaign_type"
                          >
                            סוג קמפיין
                          </label>
                          <Input
                            id="campaign_type"
                            type="select"
                            name="campaign_type"
                            placeholder="סוג הקמפיין"
                            onFocus={handleFocus}
                            onChange={handleChange}
                            onBlur={handleSubmitField}
                            required
                            value={campaign.campaign_type}
                          >
                            <option value='HK' key={1}>הוראות קבע HK</option>
                            <option value='Ragil' key={2}>תרומות Ragil</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="mosad"
                          >
                            קוד מוסד בנדרים
                          </label>
                          <Input
                            id="mosad"
                            type="text"
                            name="mosad"
                            placeholder="קוד מוסד בנדרים"
                            onFocus={handleFocus}
                            onChange={handleChange}
                            onBlur={handleSubmitField}
                            value={campaign.mosad}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="api_valid"
                          >
                            api valid
                          </label>
                          <Input
                            id="api_valid"
                            type="text"
                            name="api_valid"
                            placeholder="api valid"
                            onFocus={handleFocus}
                            onChange={handleChange}
                            onBlur={handleSubmitField}
                            value={campaign.api_valid}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Collapse>
                  <>
                    <Button block onClick={() => { setCollapse(!collapse) }} className='mr-0 mb-3 text-right bg-lighter'>
                      {!collapse ? <CaretLeftFill /> : <CaretDownFill />} הגדרות מנהל
                    </Button>
                    <Collapse isOpen={collapse}>
                      <Row>

                        {campaign.fields?.map((field, i) => (
                          <Col key={'field' + i} md="4">
                            <FormGroup className="mb-3">
                              <label
                                className="form-control-label"
                                htmlFor={field.field}
                              >
                                {field.title}
                              </label>
                              <Input
                                id={field.field}
                                type={field.type}
                                name={field.field}
                                placeholder={field.title}
                                onFocus={handleFocus}
                                onChange={handleChangeMeta}
                                onBlur={handleSubmitMetaField}
                                value={campaign.meta[field.field]}
                                meta={true}
                                disabled={user.campaign.permission > 4}
                              >
                              </Input>
                              <span className='h6 font-weight-light'>{field.note}</span>
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </Collapse>
                  </>
                  <Button
                    block
                    color="info"
                    className="bg-info"
                    onClick={() => { '' }}
                  >
                    שמור שינויים
                  </Button>
                </Form>
                {/* <Row>
              <Col sm={6} className="mt-3">
                <Button
                size="sm"
                  block
                  color="warning"
                  onClick={removeDonation}
                >
                  מחק תרומה
                </Button>
              </Col>
            </Row>
                <small className="text-red">זהירות, לא ניתן לשחזר את התרומה, לאחר לחיצה על כפתור זה!</small> */}
              </CardBody>
            </Col>
          </Row>
        </div>

      }
    </>
  );
}

export default Campaign;
