import { createContext, useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { CheckCircleFill, ExclamationTriangleFill } from 'react-bootstrap-icons'
/* import ReactHtmlParser from 'react-html-parser' */


export const AlertMsgContext = createContext()

const AlertMsgContextProvider = (props) => {

  const [alertColor, setAlertColor] = useState()
  const [alertMsg, setAlertMsg] = useState('')
  const [show, setShow] = useState(false)
  const [timer, setTimer] = useState(null)
  const msgTimer = 5  //seconds

  const showAlert = async (msg, color) =>{
    clearTimeout(timer)
    setShow(false)
    setAlertMsg(msg)
    setAlertColor(color)
    setShow(true)
    setTimer(setTimeout(()=>setShow(false), msgTimer*1000))
  }

  const alert = () => {
    return( 
      show && 
        <Alert 
          className="position-absolute pr-4"
          color={alertColor}
          style={{bottom: 30, right:50, zIndex:25000}} 
          toggle={() => setShow(false)}
          >
            <span className='mr-2'>
          {(alertColor==='danger' || alertColor==='warning') && <ExclamationTriangleFill />}
          {alertColor==='success' && <CheckCircleFill />}
          </span>
          <span className='mr-2'>{alertMsg}</span>
          {/* {ReactHtmlParser(alertMsg)} */}
        </Alert>
    )
  }

  return ( 
    <AlertMsgContext.Provider value = {{ alert, showAlert }}>
        {props.children}
    </AlertMsgContext.Provider>
  );
}

export default AlertMsgContextProvider;