export default function getCampaignUsers(campaign) {
    const token = localStorage.getItem("donors-jwt");
    const url = `https://lottery.donors.co.il/wp-json/donors/v1/campaign/${campaign}/users`;
    const options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}