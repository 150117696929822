import React, { useState, useContext, useEffect } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import addCampaign from 'services/addCampaign';
//context
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import getOrganizations from 'services/getOrganizations';

function AddCampaign({ setNewOrg, switchCampign, close }) {
  const [formValues, setFormValues] = useState({
    campaign: '',
    organization: '',
    campaign_type: "HK",
    start_time: new Date().toJSON().slice(0, 19).replace('T', ' '),
    end_time: addDays(30),
    campaign_goal_type: 'סכום',
    matrimim_goal_type: 'סכום',
    bars_goal_type: 'סכום',
    show_timer: 1,
    show_campaign_goal: 1
  });
  const [pending, setPending] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [sent, setSent] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  function addDays(days) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return new Date(result).toJSON().slice(0, 19).replace('T', ' ');
  }

  function callgetOrganizations() {
    getOrganizations()
      .then(
        (response) => {
          setOrganizations(response);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת תקשורת', 'danger')
      }
      );
  }

  useEffect(() => {
    callgetOrganizations();
  }, []);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonor();
  }

  function setDonor() {
    setPending(true);
    addCampaign(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            showAlert('הקמפיין נוצר בהצלחה', 'success');
            switchCampign(data);
            setPending(false);
            close(false);
          }
        })
      .catch((err) => {
        showAlert('שגיאת תקשורת', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      <div>
        <CardHeader className="text-center pb-0 mt-2">
          <h1>קמפיין חדש</h1>
        </CardHeader>
        <CardBody>
          <Form role="form" onSubmit={handleSubmit}>

            <div className="mb-3">
              <label
                className="form-control-label"
                htmlFor="organization"
              >
                ארגון
              </label>
              <Row><Col sm={8}>
                <Input
                  id="organization"
                  type="select"
                  name="organization"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={formValues.organization}
                  required
                >
                  <option value={''} key={0}>בחר</option>
                  {organizations.map((option) => (
                    <option value={option.id} key={option.id}>{option.organization}</option>
                  ))}
                </Input>
              </Col>
                <Col sm={4}>
                  <Button
                    block
                    color="info"
                    onClick={() => setNewOrg(true)}
                  >
                    ארגון חדש
                  </Button>
                </Col>
              </Row>
            </div>
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="amount"
              >
                שם הקמפיין
              </label>
              <Input
                required
                id="campaign"
                type="text"
                name="campaign"
                placeholder="הקמפיין"
                onChange={handleChange}
                onBlur={handleChange}
                value={formValues.campaign}
              />
            </FormGroup>
            <Button
              block
              color="info"
              className="bg-info"
              type="submit"
            >
              צור קמפיין
            </Button>
          </Form>
        </CardBody>
      </div>
    </>
  );
}

export default AddCampaign;
