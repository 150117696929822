export default function updateDonation(data) {
    let url = `https://lottery.donors.co.il/wp-json/shpro/v1/donation/update`;

    let formData = new FormData();
    for (const name in data) {
        formData.append(name, data[name]);
    }

    let headers = new Headers();
    headers.set('Accept', 'application/json');

    const options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers,
        body: formData
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}