import React, { useState, useContext } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getTeamDonorsCount from 'services/getTeamDonorsCount';
import updateTeam from 'services/updateTeam';
import updateTeamName from 'services/updateTeamName';
import deleteTeam from 'services/deleteTeam';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function EditTeam({ modalCallback, teamProps, callget, callgetType }) {
  const { id, team, type, goal, donorsGoal, password, campaign, user_name, permission } = teamProps
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState({
    id: id,
    campaign: campaign,
    team: team,
    type: type,
    goal: goal,
    donorsGoal: donorsGoal,
    password: password,
    user_name: user_name,
    permission: permission,
    newName: ''
  });
  const [pending, setPending] = useState(false);
  const [editNameForm, setEditNameForm] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTeam();
  }

  const handleSubmitEditName = async (e) => {
    e.preventDefault();
    submitEditName();
  }

  function editName() {
    setPending(true);
    getTeamDonorsCount(formValues.team, formValues.type, formValues.campaign)
      .then(
        (response) => {
          if (!response.length) {
            showAlert('לא ניתן לעדכן שם', 'danger')
            setPending(false);
          }
          else {
            setEditNameForm(response[0].count);
            setPending(false);
          }
        })
      .catch((err) => {
        showAlert('service error', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function removeTeam() {
    setPending(true);
    deleteTeam(formValues.id)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            callget(callgetType);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function setTeam() {
    setPending(true);
    updateTeam(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            callget(callgetType);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function submitEditName() {
    setPending(true);
    updateTeamName(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            callget(callgetType);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      {user.campaign.permission > 10 ?
        <div>
          <h1 className="text-center m-4">עריכת מתרים</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        !editNameForm ?
          <div>
            <CardHeader className="text-center">
              <h2>עריכת מתרים {id}</h2>
              <h1>{team}</h1>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={handleSubmit}>
                <Row>
                  <Col sm="8">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="team"
                      >
                        שם
                      </label>
                      <Input
                        id="team"
                        type="text"
                        name="team"
                        placeholder="שם"
                        required
                        value={formValues.team}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label text-lighter"
                        htmlFor="team"
                      >
                        {"*"}
                      </label>
                      <Button
                        size="md"
                        block
                        color="info"
                        className="bg-info"
                        onClick={editName}
                      >
                        ערוך שם
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        סוג
                      </label>
                      <Input
                        id="type"
                        type="select"
                        name="type"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.type}
                      >
                        <option value='3' key={3}>קהילה</option>
                        <option value='1' key={1}>מתרים</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  {/* <Col md="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="permission"
                      >
                        הרשאה
                      </label>
                      <Input
                        id="permission"
                        type="select"
                        name="permission"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.permission}
                      >
                        <option value='1' key={1}>1 - עריכה</option>
                        <option value='2' key={2}>2 - הוספת תרומות</option>
                        <option value='3' key={3}>3 - צפיה בלבד</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="goal"
                      >
                        יעד כספי
                      </label>
                      <Input
                        id="goal"
                        type="number"
                        name="goal"
                        placeholder="יעד בשקלים"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.goal}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="donorsGoal"
                      >
                        יעד תורמים
                      </label>
                      <Input
                        id="donorsGoal"
                        type="number"
                        name="donorsGoal"
                        placeholder="יעד תורמים"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.donorsGoal}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="user_name"
                      >
                        שם משתמש
                      </label>
                      <Input
                        id="user_name"
                        type="text"
                        name="user_name"
                        placeholder="שם משתמש"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.user_name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="password"
                      >
                        סיסמה
                      </label>
                      <Input
                        id="password"
                        type="text"
                        name="password"
                        placeholder="סיסמה"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.password}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Button
                  block
                  color="info"
                  className="bg-info"
                  type="submit"
                >
                  שמור מתרים
                </Button>
              </Form>
              <Row>
                <Col sm={6} className="mt-3">
                  <Button
                    size="sm"
                    block
                    color="warning"
                    onClick={removeTeam}
                  >
                    מחק מתרים
                  </Button>
                </Col>
              </Row>
              <small className="text-red">זהירות, לא ניתן לשחזר את המתרים, לאחר לחיצה על כפתור זה!</small>

            </CardBody>
          </div>
          :
          <div>
            <CardHeader className="text-center">
              <h2>עריכת מתרים {id}</h2>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={handleSubmitEditName}>
                <Row>
                  <Col sm="12">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="team"
                      >
                        שם
                      </label>
                      <Input
                        id="team"
                        type="text"
                        name="team"
                        placeholder="שם"
                        required
                        value={formValues.team}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="newName"
                      >
                        שם חדש
                      </label>
                      <Input
                        id="newName"
                        type="text"
                        name="newName"
                        placeholder="שם חדש"
                        required
                        value={formValues.newName}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                  <h3 className='text-center'>
                    השינוי יתבצע למתרים
                    ול-{editNameForm} תורמים שמשויכים אליו
                  </h3>
                <Button
                  block
                  color="info"
                  className="bg-info"
                  type="submit"
                >
                  שנה שם
                </Button>
              </Form>
              <Row>
                <Col sm={6} className="mt-3">
                  <Button
                    size="sm"
                    block
                    color="warning"
                    onClick={removeTeam}
                  >
                    מחק מתרים
                  </Button>
                </Col>
              </Row>
              <small className="text-red">זהירות, לא ניתן לשחזר את המתרים, לאחר לחיצה על כפתור זה!</small>

            </CardBody>
          </div>
      }
    </>
  );
}

export default EditTeam;
