export default function getAllDonations(campaign, limit, offset) {
    let url = 'https://lottery.donors.co.il/wp-json/shpro/v1/donations/campaign/' + campaign + '?limit=' + limit + '&offset=' + offset;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}


