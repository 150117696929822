import React, { useState, useContext, useRef, useEffect } from 'react';
// nodejs library that concatenates classes
import classnames from "classnames";
//context
import { UserContext } from "context/userContext";
import TeamsContext from 'context/TeamsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getTeams from 'services/getTeams';
//react-to-print
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  PaginationLink,
  PaginationItem,
  Pagination,
  Modal,
  CardFooter,
  Collapse,
  Table,
  CardHeader,
  FormGroup,
  Input
} from "reactstrap";
import { Diagram2, Plus, X, PencilFill } from 'react-bootstrap-icons';
// core components
import EditTeam from "views/pages/forms/EditTeam.js";
import Loader from "components/custom/Loader";
import AddTeam from '../forms/AddTeam';
import UploadXlsx from '../uploadXlsx/UploadXlsx';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        הצג {" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        שורות
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function Teams() {
  const [alert, setAlert] = useState(null);
  const componentRef = useRef(null);
  const { user } = useContext(UserContext);
  const { teams, setTeams } = useContext(TeamsContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [currentTeam, setCurrentTeam] = useState({});
  const [formModal, setformModal] = useState(false);
  const [formAddModal, setformAddModal] = useState(false);
  const [callgetType, setCallgetType] = useState(3);
  const [pending, setPending] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [filter, setFilter] = useState('');
  const [teamsFilter, setTeamsFilter] = useState(teams);

  const columns = [
    {
      dataField: "id",
      text: "מזהה",
      sort: true,
    },
    {
      dataField: "team",
      text: "מתרים",
      sort: true,
    },
    {
      dataField: "type",
      text: "סוג",
      sort: true,
    },
    {
      dataField: "goal",
      text: "יעד",
      sort: true,
    },
    {
      dataField: "donorsGoal",
      text: "יעד תורמים",
      sort: true,
    },
    {
      dataField: "total",
      text: "תרומות בש\"ח",
      sort: true,
    },
    {
      dataField: "donors",
      text: "סך תורמים",
      sort: true,
    },
    {
      dataField: "count_donations",
      text: "סך תרומות",
      sort: true,
    },
  ]

  function handleChangeFilter(e){
    const value = e.target.value;
    setFilter(value);
    const newTeamsFilter = teams.filter(function (t) {
      return t.team.includes(value)
    });
    setTeamsFilter(newTeamsFilter);
  }

  function rowEvent(row) {
    setCurrentTeam(row)
    setformModal(true);
  }

  function modalCallback(status) {
    setformModal(status);
  }

  function modalAddCallback(status) {
    setformAddModal(status);
  }

  function typeCallback(typecall) {
    setCallgetType(typecall);
  }

  function callget(type) {
    setPending(true);
    getTeams(user.campaign.id, type)
      .then(
        (response) => {
          setTeams(response);
          setPending(false);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת שרת', 'danger')
        setPending(false);
      }
      );
  }

  function callget1() {
    setCallgetType(1);
    callget(1);
  }
  function callget3() {
    setCallgetType(3);
    callget(3);
  }
  function callget2() {
    setCallgetType(2);
    callget(2);
  }

  useEffect(() => {
    callget(3);
    document.title = `donors - מתרימים`;
  }, [])


  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="מעולה!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        המידע הועתק ללוח
      </ReactBSAlert>
    );
  };

  const roles = ['administrator', 'manager','agent', 'leader' ];
  
  return (
    <>
      {!roles.includes(user.campaign.role) && user.campaign.permission > 10 ?
        <div>
          <div className="text-center mt-4"><Diagram2 size={75} className="mb-1" /></div>
          <h1 className="text-center m-0">מתרימים / קבוצות</h1>
          <h4 className="text-center m-4">הרשאת מנהל בלבד</h4>
        </div> :
        <div>
          {alert}
          <h1 className="text-center m-3 text-donors-pink"><Diagram2 size={24} className="mb-1" /> מתרימים / קבוצות</h1>
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formModal}
            toggle={() => setformModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <EditTeam
                  modalCallback={modalCallback}
                  callget={callget}
                  callgetType={callgetType}
                  teamProps={currentTeam}
                />
              </Card>
            </div>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formAddModal}
            toggle={() => setformAddModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <AddTeam
                  modalCallback={modalAddCallback}
                  callget={callget}
                  typeCallback={typeCallback}
                />
              </Card>
            </div>
          </Modal>
          <Container className="" fluid>
            <Row className='justify-content-between'>
              <Col>
                <Button
                  className={classnames(
                    "text-white m-2",
                    callgetType === 3 ? 'bg-dark' : 'bg-light'
                  )}
                  onClick={callget3}
                >
                  קהילות
                </Button>
                <Button
                  className={classnames(
                    "text-white m-2",
                    callgetType === 1 ? 'bg-dark' : 'bg-light'
                  )}
                  onClick={callget1}
                >
                  מתרימים
                </Button>
              </Col>
              <Col className='text-left'>
                <Button size="md" onClick={() => { setCollapse(!collapse) }}
                  color='light' className='mr-2 border-0'>
                  {!collapse ? <Plus size={20} /> : <X size={20} />} ייבוא מתרימים
                </Button>
                <Button
                  className="btn-donors-blue mr-3"
                  size="md"
                  onClick={() => setformAddModal(true)}
                >
                  הוסף מתרים / קהילה
                </Button>
              </Col>
            </Row>
          </Container>
          <Collapse isOpen={collapse}>
            <UploadXlsx
              tableName={{ title: 'מתרימים', table: 'teams' }}
              callget={callget}
              callgetType={callgetType}
            />
          </Collapse>
          <Container fluid>
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                  <FormGroup className="form-inline ml-4 mb-2">
                      <label>
                        חפש:
                      </label>
                      <Input
                        id="filter"
                        type="text"
                        name="filter"
                        size={'sm'}
                        className="mr-1"
                        onChange={handleChangeFilter}
                        value={filter}
                        placeholder="חפש וסנן"
                      >
                      </Input>
                    </FormGroup>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    {pending && <Loader text="" />}
                    <thead className="thead-light">
                      <tr>
                        {columns.map((option) => (
                          <th className={option.sort ? 'sort ' : ''} data-sort={option.dataField} >
                            <span className='ml-1'>{option.text}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="list">
                      {teamsFilter?.map((option) => (
                        <tr onClick={() => rowEvent(option)} role="button">
                          {/* <th scope="row">
                            <PencilFill className='ml-4' />
                            <span className="name mb-0 text-sm">
                              {option.user}
                            </span>
                          </th> */}
                          {columns.map((col) => (
                            <td >
                              {option[col.dataField]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            2 <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            3
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>}
    </>
  );
}

export default Teams;
