import { useEffect, useState, useContext } from "react";
import { UserContext } from "context/userContext";
import { Button, Card, CardTitle, Form, Input, Container, Row, Col, CardBody } from "reactstrap";
import { AlertMsgContext } from "context/AlertMsgContext";
import Load from "views/pages/examples/Load";

function LoginPage() {

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, tokenUrl, setToken } = useContext(UserContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [pending, setPending] = useState(false);

  const options = {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: email, password })
  }

  const loginUser = (e) => {
    e.preventDefault()
    setPending(true);
    fetch(tokenUrl, options)
      .then(res => res.json())
      .then(res => {
        if (res.token) {
          localStorage.setItem('donors-jwt', res.token);
          setToken(res.token);
          showAlert('התחברת בהצלחה', 'success');
        }
        else {
          showAlert(res.message, 'danger');
          setPending(false);
          throw new Error(res.message);
        }
      })
      .catch(err => {
        showAlert(err.message, 'danger');
        setPending(false);
        console.log(err, err.message);
      })
  }

  useEffect(() => {
    if (user) {
      setPending(false);
    }
  }, [user])

  return (
    <>
      {pending ? <Load title='כניסת משתמש'/> :
        <div className="wrapper">
          <div className="page-header"
          >
            <div className="filter" />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                  <Card className="card-register mt-3 text-center">
                    <CardTitle className="mt-5 mb-3" tag="h3">
                      <a href={`https://lottery.donors.co.il`}>
                        <img
                          alt="logo"
                          src={require("assets/img/brand/logo.png").default}
                        />
                      </a>
                    </CardTitle>
                    <CardBody>
                      <Form className="register-form " onSubmit={loginUser}>
                        <Input className="p-2 mb-3" id='email' placeholder="אימייל" type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Input className="p-2 mb-4" id='password' placeholder="סיסמא" type="password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button block className="btn-round btn-donors-pink" type="submit" >
                          התחבר
                        </Button>
                      </Form>
                      <Row className="mt-5">
                        <Col className="text-right">
                          <a href={`https://lottery.donors.co.il/wp-login.php?action=lostpassword`}
                          >
                            <span className="">שכחתי סיסמה</span>
                          </a>
                        </Col>
                        <Col className="text-left">
                          <a href={`https://lottery.donors.co.il`}
                          >
                            <span className="">לדף הבית</span>
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>}
    </>
  );
}

export default LoginPage;
