import React, { useState, useContext } from 'react';
//context
import DonorsContext from 'context/DonorsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import updateDonor from 'services/updateDonor';
import deleteDonation from 'services/deleteDonation';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function EditDonor({ donor, modalCallback, callget, teamsOptions3, teamsOptions1 }) {
  const { id, name, phone, phone2, phone3, phone4, address, email, old_hok, team3, campaign, team1, terminal, apt_id, first_name, last_name, note, status } = donor
  const { user: currentUser } = useContext(UserContext);
  const [formValues, setFormValues] = useState({
    id: id,
    name: name,
    phone: phone,
    phone2: phone2,
    phone3: phone3,
    phone4: phone4,
    address: address,
    old_hok: old_hok,
    campaign: campaign,
    team1: team1,
    team3: team3,
    terminal: terminal,
    apt_id: apt_id,
    first_name: first_name,
    last_name: last_name,
    note: note,
    status: status,
    email: email,
  });
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { donors, setDonors } = useContext(DonorsContext);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonor();
  }

  function removeDonor() {
    setPending(true);
    deleteDonation(formValues.id)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            /* setSent(true); */
            /* setformModal(false); */
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function setDonor() {
    setPending(true);
    updateDonor(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            /* setSent(true); */
            modalCallback(false);
            callget(250, 0);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      {currentUser.type != 2 ?
        <div>
          <h1 className="text-center m-4">עריכת תורם</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          <CardHeader className="text-center">
            <h1>עריכת תורם {name}</h1>
            <h3>מס' {id}</h3>
          </CardHeader>
          <CardBody>
            <Form role="form" onSubmit={handleSubmit}>
              <Row>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="amount"
                    >
                      שם מלא
                    </label>
                    <Input
                      id="name"
                      type="text"
                      name="name"
                      placeholder="שם מלא"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.name}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone"
                    >
                      פלאפון
                    </label>
                    <Input
                      id="phone"
                      type="phone"
                      name="phone"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="address"
                    >
                      כתובת
                    </label>
                    <Input
                      id="address"
                      type="text"
                      name="address"
                      placeholder="כתובת'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.address}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="first_name"
                    >
                      שם פרטי
                    </label>
                    <Input
                      id="first_name"
                      type="text"
                      name="first_name"
                      placeholder="שם פרטי"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.first_name}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="last_name"
                    >
                      שם משפחה
                    </label>
                    <Input
                      id="last_name"
                      type="text"
                      name="last_name"
                      placeholder="שם משפחה"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.last_name}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="status"
                    >
                      סטטוס
                    </label>
                    <Input
                      id="status"
                      type="text"
                      name="status"
                      placeholder="סטטוס"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.status}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="old_hok"
                    >
                      תרומה קודמת
                    </label>
                    <Input
                      id="old_hok"
                      type="text"
                      name="old_hok"
                      placeholder="סכום תרומה קודמת"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.old_hok}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team3"
                    >
                      נציג / קהילה
                    </label>
                    <Input
                      id="team3"
                      type="select"
                      name="team3"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.team3}
                    >
                      {teamsOptions3.map((option) => (
                        <option value={option.team} key={option.id}>{option.team}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team1"
                    >
                      מתרים
                    </label>
                    <Input
                      id="team1"
                      type="select"
                      name="team1"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.team1}
                    >
                      {teamsOptions1.map((option) => (
                        <option value={option.team} key={option.id}>{option.team}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="apt_id"
                    >
                      מזהה apt
                    </label>
                    <Input
                      id="apt_id"
                      type="text"
                      name="apt_id"
                      placeholder="מזהה apt"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.apt_id}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="email"
                    >
                      אימייל
                    </label>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="אמייל"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.email}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="note"
                    >
                      הערה
                    </label>
                    <Input
                      id="note"
                      type="text"
                      name="note"
                      placeholder="הערה"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.note}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone2"
                    >
                      פלאפון 2
                    </label>
                    <Input
                      id="phone2"
                      type="phone"
                      name="phone2"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone2}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone3"
                    >
                      פלאפון 3
                    </label>
                    <Input
                      id="phone3"
                      type="phone"
                      name="phone3"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone3}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="phone4"
                    >
                      פלאפון 4
                    </label>
                    <Input
                      id="phone4"
                      type="phone"
                      name="phone4"
                      placeholder="מס טל' / פלא'"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.phone4}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                block
                color="info"
                className="bg-info"
                type="submit"
              >
                שמור שינויים
              </Button>
            </Form>
            {/* <Row>
              <Col sm={6} className="mt-3">
                <Button
                size="sm"
                  block
                  color="warning"
                  onClick={removeDonation}
                >
                  מחק תרומה
                </Button>
              </Col>
            </Row>
                <small className="text-red">זהירות, לא ניתן לשחזר את התרומה, לאחר לחיצה על כפתור זה!</small> */}
          </CardBody>
        </div>}
    </>
  );
}

export default EditDonor;
