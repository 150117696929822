import React, { useState, useContext } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
import { CampaignContext } from "context/campaignContext";
import { UserContext } from "context/userContext";
//services
import addTeam from 'services/addTeam';
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function AddTeam({ modalCallback, callget, typeCallback }) {
  const { user } = useContext(UserContext);
  const { campaign } = useContext(CampaignContext);
  const [formValues, setFormValues] = useState({
    campaign: campaign.id,
    team: '',
    type: 3,
    goal: '',
    donorsGoal: '',
    password: 'donors02',
    user_name: 'donors02',
    permission: 3,
  });
  const [pending, setPending] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTeam();
  }

  function setTeam() {
    setPending(true);
    addTeam(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('משתמש לא תקין' + data, 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            typeCallback(formValues.type);
            callget(formValues.type);
          }
        })
      .catch((err) => {
        showAlert('service error', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      {user.campaign.permission > 10 ?
        <div>
          <h1 className="text-center m-4">הוספת קבוצה</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          <CardHeader className="text-center">
            <h1>הוספת קבוצה</h1>
          </CardHeader>
          <CardBody>
            <Form role="form" onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="team"
                    >
                      שם
                    </label>
                    <Input
                      id="team"
                      type="text"
                      name="team"
                      placeholder="שם"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.team}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="type"
                    >
                      סוג
                    </label>
                    <Input
                      id="type"
                      type="select"
                      name="type"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.type}
                    >
                      <option value='3' key={3}>קהילה</option>
                      <option value='1' key={1}>מתרים</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="permission"
                    >
                      הרשאה
                    </label>
                    <Input
                      id="permission"
                      type="select"
                      name="permission"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.permission}
                    >
                      <option value='1' key={1}>1 - עריכה</option>
                      <option value='2' key={2}>2 - הוספת תרומות</option>
                      <option value='3' key={3}>3 - צפיה בלבד</option>
                    </Input>
                  </FormGroup>
                </Col> */}
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="goal"
                    >
                      יעד כספי
                    </label>
                    <Input
                      id="goal"
                      type="number"
                      name="goal"
                      placeholder="יעד בשקלים"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.goal}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="donorsGoal"
                    >
                      יעד תורמים
                    </label>
                    <Input
                      id="donorsGoal"
                      type="number"
                      name="donorsGoal"
                      placeholder="יעד תורמים"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.donorsGoal}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="user_name"
                    >
                      שם משתמש
                    </label>
                    <Input
                      id="user_name"
                      type="text"
                      name="user_name"
                      placeholder="שם משתמש"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.user_name}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="password"
                    >
                      סיסמה
                    </label>
                    <Input
                      id="password"
                      type="text"
                      name="password"
                      placeholder="סיסמה"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.password}
                    />
                  </FormGroup>
                </Col>
              </Row> */}
              <Button
                block
                color="info"
                className="bg-info"
                type="submit"
              >
                הוסף חדש
              </Button>
            </Form>
          </CardBody>
        </div>}
    </>
  );
}

export default AddTeam;