import React, { useState, useContext, useEffect } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import addOrganization from 'services/addOrganization';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function AddOrganization({ setNewOrg }) {
  const [formValues, setFormValues] = useState({
    organization: '',
    comments: ''
  });
  const [pending, setPending] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonor();
  }

  function setDonor() {
    setPending(true);
    addOrganization(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            showAlert('הארגון נוסף בהצלחה', 'success')
            setPending(false);
            setNewOrg(false)
          }
        })
      .catch((err) => {
        showAlert('שגיאת תקשורת', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      <div>
        <CardHeader className="pb-0 mt-2">
          <Button
            className='mr-auto'
            size='sm'
            color="dark"
            onClick={() => setNewOrg(false)}
          >
            חזור לרשימת הארגונים
          </Button>
          <h1 className='text-center '>הוספת ארגון</h1>
        </CardHeader>
        <CardBody>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="organization"
              >
                שם הארגון
              </label>
              <Input
                required
                id="organization"
                type="text"
                name="organization"
                placeholder="שם המוסד / הארגון"
                onChange={handleChange}
                onBlur={handleChange}
                value={formValues.organization}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="amount"
              >
                פרטים נוספים
              </label>
              <Input
                id="comments"
                type="textarea"
                name="comments"
                placeholder="פרטים נוספים"
                onChange={handleChange}
                onBlur={handleChange}
                value={formValues.comments}
              />
            </FormGroup>
            <Button
              block
              color="info"
              className="bg-info"
              type="submit"
            >
              הוסף ארגון
            </Button>
          </Form>
        </CardBody>
      </div>
    </>
  );
}

export default AddOrganization;
