import React, { useState, useEffect, useContext } from 'react';
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
//context
import SidenavOpenContext from 'context/SidenavOpenContext';
import SettingsContext from 'context/SettingsContext';
/* import CampaignContext from 'context/CampaignContext'; */
import TeamsContext from 'context/TeamsContext';
import DonorsContext from 'context/DonorsContext';
import DonationsContext from 'context/DonationsContext';
import { UserContext } from "context/userContext";
import { AlertMsgContext } from "context/AlertMsgContext";
import { CampaignContext } from 'context/campaignContext.js';
//services
import getUser from 'services/getUser';
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/icomoon/style.css";
import "assets/css/custom/custom.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import AppLayout from "./layouts/AppLayout.js";
import AuthLayout from "layouts/Auth.js";
import Load from "views/pages/examples/Load";
/* import AlertError from "components/custom/AlertError"; */
import PageNotFound from "components/PageNotFound";
import LoginPage from "components/LoginPage";

const App = () => {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [settings, setSettings] = useState('');
  const [campaign, setCampaign] = useState({});
  const [teams, setTeams] = useState([]);
  const [donors, setDonors] = useState([]);
  const [donations, setDonations] = useState([]);

  const { isLoading, user } = useContext(UserContext)
  const { alert } = useContext(AlertMsgContext)

  return (
    <BrowserRouter>
      {(isLoading)? <Load /> :
        <SidenavOpenContext.Provider value={{ sidenavOpen, setSidenavOpen }}>
          <SettingsContext.Provider value={{ settings, setSettings }}>
            {/* <CampaignContext.Provider value={{ campaign, setCampaign }}> */}
            <TeamsContext.Provider value={{ teams, setTeams }}>
              <DonorsContext.Provider value={{ donors, setDonors }}>
                <DonationsContext.Provider value={{ donations, setDonations }}>
                  {/* {errorMsg && <AlertError />} */}
                  {alert()}
                  <Switch>
                    {user ?
                      <Switch>
                        <Route path="/" render={(props) => <AppLayout {...props} />} />
                        <Route path="*" render={props => <PageNotFound {...props} />} />
                      </Switch>
                      :
                      <>
                        <Route path="/" render={props => <LoginPage {...props} />} />
                        <Redirect to="/" />
                      </>
                    }
                  </Switch>
                </DonationsContext.Provider>
              </DonorsContext.Provider>
            </TeamsContext.Provider>
            {/* </CampaignContext.Provider> */}
          </SettingsContext.Provider>
        </SidenavOpenContext.Provider>
      }
    </BrowserRouter>
  );
}
export default App;