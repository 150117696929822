import React from "react";
// reactstrap components

// core components
import CardsHeaderAchim from "components/Headers/CardsHeaderAchim.js";

function DashboardAchim() {

  return (
    <>
      <CardsHeaderAchim/>
      </>
  );
}

export default DashboardAchim;
