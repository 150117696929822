import React, { useState, useContext, useRef, useEffect } from 'react';
// nodejs library that concatenates classes
import classnames from "classnames";
//context
import { UserContext } from "context/userContext";
import TeamsContext from 'context/TeamsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getCampaignUsers from 'services/getCampaignUsers';
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
} from "reactstrap";
import { Pen, PencilFill, PersonCircle } from 'react-bootstrap-icons';
// core components
import EditTeam from "views/pages/forms/EditTeam.js";
import Loader from "components/custom/Loader";
import AddUser from '../forms/AddUser';
import EditUser from '../forms/EditUser';

function Users() {
  const componentRef = useRef(null);
  const { user } = useContext(UserContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [donorsUsers, setDonorsUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [formModal, setformModal] = useState(false);
  const [formAddModal, setformAddModal] = useState(false);
  const [pending, setPending] = useState(false);
  //const { teams, setTeams } = useContext(TeamsContext);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentUser(row)
      setformModal(true);
    }
  };

  function modalCallback(status) {
    setformModal(status);
  }

  function modalAddCallback(status) {
    setformAddModal(status);
  }

  function callget() {
    setPending(true);
    getCampaignUsers(user.campaign.id)
      .then(
        (response) => {
          const { success } = response;
          if (!success) {
            showAlert('שגיאה C741', 'danger')
            setPending(false);
          }
          else {
            setDonorsUsers(response.data);
            setPending(false);
          }
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת שרת', 'danger')
        setPending(false);
      }
      );
  }

  useEffect(() => {
    callget();
    document.title = `donors - משתמשים`;
  }, [])

  const roles = ['administrator', 'manager','agent', 'leader' ];
  
  return (
    <>
      {!roles.includes(user.campaign.role) && user.campaign.permission > 7 ?
        <div>
          <div className="text-center mt-4"><PersonCircle size={75} className="mb-1" /></div>
          <h1 className="text-center m-0">משתמשים</h1>
          <h4 className="text-center m-4">הרשאת מנהל בלבד</h4>
        </div> :
        <div>
          <h1 className="text-center m-3 text-donors-pink"><PersonCircle size={22} className="mb-1" /> משתמשים</h1>
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formModal}
            toggle={() => setformModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <EditUser
                  modalCallback={modalCallback}
                  callget={callget}
                  userProps={currentUser}
                />
              </Card>
            </div>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formAddModal}
            toggle={() => setformAddModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <AddUser
                  modalCallback={modalAddCallback}
                  callget={callget}
                />
              </Card>
            </div>
          </Modal>
          <Container className="mt--4 mb-2" fluid>
            <Row className='justify-content-between'>
              <Col className='text-left'>
                <Button
                  className="btn-donors-blue mr-3"
                  size="md"
                  onClick={() => setformAddModal(true)}
                >
                  הוספת משתמש
                </Button>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    {pending && <Loader text="" />}
                    <thead className="thead-light">
                      <tr>
                        <th className="sort pr-5" data-sort="name" >
                          id
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          שם משתמש
                        </th>
                        <th className="sort" data-sort="status" scope="col">
                          אימייל
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          סוג
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          הרשאה
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {donorsUsers?.map((option) => (
                        <tr onClick={() => setformModal(true)} role="button">
                          <td scope="row">
                            <PencilFill className='ml-4' />
                            <span className="name mb-0 text-sm">
                              {option.user}
                            </span>
                          </td>
                          <th className="budget">{option.display_name}</th>
                          <td>
                            <span className="status">{option.user_email}</span>
                          </td>
                          <td>
                            <span className="completion ml-2">{option.role}</span>
                          </td>
                          <td>
                            <span className="completion ml-2">{option.permission}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            2 <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            3
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>}
    </>
  );
}

export default Users;
