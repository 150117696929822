import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
// react plugin that prints a given react component
//context
import { UserContext } from "context/userContext";
import { CampaignContext } from "context/campaignContext";
import DonorsContext from 'context/DonorsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getTeamDonors from 'services/getTeamDonors';
import getDonors from 'services/getDonors';
import getNedarimDonors from 'services/getNedarimDonors';
import getNedarimDonorsDetected from 'services/getNedarimDonorsDetected';
import getDonorsByName from 'services/getDonorsByName';
import getTeams from 'services/getTeams';
//react-to-print
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
/* import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"; */
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  PaginationLink,
  PaginationItem,
  Pagination,
  Modal,
  CardFooter,
  Collapse,
  Table,
  CardHeader,
  Input,
  CardBody,
  FormGroup
} from "reactstrap";
import { BinocularsFill, ChevronDown, Coin, Search, FunnelFill, PencilSquare, People, PeopleFill, Plus, X, XLg } from 'react-bootstrap-icons';
// core components
import AddLotteryDonation from "views/pages/forms/AddLotteryDonation.js";
import NedarimIframe from "views/pages/forms/NedarimIframe.js";
import EditDonor from "views/pages/forms/EditDonor.js";
import AddDonor from '../forms/AddDonor';
import Loader from "components/custom/Loader";
import EditDonorStatus from "views/pages/forms/EditDonorStatus.js";
import ExpotCsv from "services/ExpotCsv.js";
import UploadXlsx from '../uploadXlsx/UploadXlsx';

function Donors() {
  const [alert, setAlert] = useState(null);
  const boxRef = useRef();
  const { user } = useContext(UserContext);
  const { campaign } = useContext(CampaignContext);
  const { donors, setDonors } = useContext(DonorsContext);
  const [donorsCount, setDonorsCount] = useState(0);
  const { showAlert } = useContext(AlertMsgContext);

  const [formModal, setformModal] = useState(false);
  const [nedarimModal, setNedarimModal] = useState(false);
  const [formDonorModal, setFormDonorModal] = useState(false);
  const [formSelectModal, setFormSelectModal] = useState(false);
  const [formAddModal, setFormAddModal] = useState(false);

  const [currentDonor, setCurrentDonor] = useState({});
  const [filtersValues, setFiltersValues] = useState({
    text: '',
    team1: '0',
    team3: '0',
    status: '0',
    status2: '0',
    status3: '0',
  });
  const [text, setText] = useState('');
  const [amount, setAmount] = useState(30);
  const [filterText, setFilterText] = useState(false);
  const [teamsOptions1, setteamsOptions1] = useState([]);
  const [teamsOptions3, setteamsOptions3] = useState([]);
  const [pending, setPending] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const [order, setOrder] = useState('id');
  const [orderdir, setOrderdir] = useState('ASC');
  const [donorsTo, setDonorsTo] = useState(amount);
  const [editField, setEditField] = useState('');

  const [windowSize, setWindowSize] = useState(550);

  const getPosition = () => {
    const y = boxRef.current.offsetTop;
    const w = window.outerHeight;
    const b = window.outerHeight * 0.18;
    setWindowSize(w - y - b);
  };

  useEffect(() => {
    getPosition();

    //  window.addEventListener("resize", getPosition);

    window.onresize = () => {
      getPosition();
    };
  }, []);

  const statusOptions = [
    { status: '(ריק)', value: '' },
    { status: 'תרם', value: 'Donated' },
    { status: 'לא רלוונטי', value: 'לא רלוונטי' },
  ];


  const columns = [
    {
      dataField: "id",
      text: "מזהה",
      sort: true,
    },
    {
      dataField: "name",
      text: "שם",
      sort: true,
      classes: 'edit-donor-cell'
    },
    {
      dataField: "phone",
      text: "טל'",
      sort: true,
    },
    {
      dataField: "phone2",
      text: "טל' 2",
      sort: true,
    },
    {
      dataField: "address",
      text: "כתובת",
      sort: true,
    },
    {
      dataField: "city",
      text: "עיר",
      sort: true,
    },
    {
      dataField: "team1",
      text: "מתרים",
      sort: true,
    },
    {
      dataField: "team3",
      text: "קהילה",
      sort: true,
    },
    {
      dataField: "status",
      text: "סטטוס",
      sort: true,
      edit: true,
    },
    {
      dataField: "amount",
      text: "תרומות בש\"ח",
      sort: true,
    },
    {
      dataField: "count_donations",
      text: "סך תרומות",
      sort: true,
    },
    {
      dataField: "status2",
      text: user.campaign.meta.status2_title || 'סטטוס 2',
      sort: true,
    },
    {
      dataField: "status3",
      text: user.campaign.meta.status3_title || 'סטטוס 3',
      sort: true,
    },
    {
      dataField: "note",
      text: "הערה",
      sort: true,
    },
    {
      dataField: "terminal",
      text: "מקור",
      sort: true,
    },
  ]


  function showMore() {
    setDonorsTo(donorsTo + amount);
    callget(donorsTo + amount, 'add');
  }

  useEffect(() => {
    callget(0, 'restart');
  }, [order, filtersValues, orderdir])

  const handleChangeFilter = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFiltersValues({
      ...filtersValues,
      [name]: value
    });
  }

  const handleChangeText = () => {
    setFiltersValues({
      ...filtersValues,
      ['text']: text
    });
  }

  function donateModal(currentDonorI) {
    setCurrentDonor(donors[currentDonorI]);
    setformModal(true);
  }

  function donateModalAfterAddDonor(newDonor) {
    if (newDonor.length) {
      setCurrentDonor(newDonor[0]);
      setformModal(true);
    }
  }

  function modalCallback(status) {
    setformModal(status)
  }
  function modalCallbackEditDonor(status) {
    setFormDonorModal(status)
  }
  function modalCallbackAddDonor(status) {
    setFormAddModal(status)
  }

  const history = useHistory();

  function toDonorPage(currentDonorId) {
    let path = `donor/${currentDonorId}`;
    history.push(path);
  }

  function textToAarray(text) {
    return text.split(",");
  }


  function getUrl(columns) {
    if (user.type == 1 || user.type == 3 || filtersValues.team1 != '0' || filtersValues.team3 != '0') {
      let filterType = '';
      let filterTeam = '';
      if (filtersValues.team1 !== '0') {
        filterType = 1
        filterTeam = filtersValues.team1
      }
      else if (filtersValues.team3 !== '0') {
        filterType = 3
        filterTeam = filtersValues.team3
      }
      else {
        filterType = user.type
        filterTeam = user.team
      }
      return 'https://lottery.donors.co.il/wp-json/shpro/v1/donors/type/' + filterType + '?team=' + filterTeam + '& campaign=' + user.campaign.id + '&status=' + filtersValues.status + '&text=' + filtersValues.text + '&columns=' + columns;
    }
    else {
      return 'https://lottery.donors.co.il/wp-json/shpro/v1/donors/campaign/' + user.campaign.id + '?limit=' + 4000 + '&offset=' + 0 + '&status=' + filtersValues.status + '&text=' + filtersValues.text + '&columns=' + columns;
    }
  }

  function callget(offset, method) {
    if (method === 'restart') {
      setPending(true);
      setDonorsTo(amount);
    }
    getDonors(user.campaign.id, method === 'restart' ? amount * 2 : amount,
      offset, filtersValues, order, orderdir)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setPending(false);
            showAlert('אין רשומות', 'warning');
          }
          else {
            method === 'add' ? setDonors([...donors, ...data.data])
              : setDonors(data.data);
            setDonorsCount(data.count[0].count);
            setPending(false);
          }
        })
      .catch((err) => {
        console.log('Error! c', err);
        setPending(false);
        showAlert('שגיאת עדכון', 'danger');
      }
      );
  }

  function callgetTeams(type) {
    getTeams(user.campaign.id, type)
      .then(
        (response) => {
          function compare(a, b) {
            if (a.team > b.team) {
              return 1;
            }
            if (a.team < b.team) {
              return -1;
            }
            return 0;
          }
          response.sort(compare);
          if (type === 1) {
            setteamsOptions1(response);
          }
          else {
            setteamsOptions3(response);
          }
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת תקשורת', 'danger')
      }
      );
  }

  useEffect(() => {
    document.title = `Lottors - תורמים`;
    //callget();
    callgetTeams(1);
    callgetTeams(3);
  }, [])

  return (
    <>
      <div>
        {alert}
        <div>
          <h1 className="text-center m-3 text-donors-pink"><PeopleFill size={23} className="mb-1" /> תורמים</h1>
        </div>
        {user.campaign.permission < 20 &&
          <Container fluid className='text-left mt--5 mb-3' >
            {user.campaign.permission < 10 && <Button size="md" onClick={() => { setCollapse(!collapse) }}
              color='light' className='mr-2 border-0'>
              {!collapse ? <Plus size={20} /> : <X size={20} />} ייבוא תורמים
            </Button>}
            <Button
              className="btn-donors-blue mr-3"
              size="md"
              onClick={() => setFormAddModal(true)}
            >
              תורם חדש
            </Button>
          </Container>
        }
        <Modal
          className="modal-dialog-centered text-center"
          size="md"
          isOpen={formSelectModal}
          toggle={() => setFormSelectModal(false)}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0">
              <CardHeader className="text-center">
                <h1>תורם: {currentDonor.name}</h1>
                <h3>מס' {currentDonor.id}</h3>
                <h4>{currentDonor.team3} | {currentDonor.amount < 1 ? '0 ש"ח' :
                  currentDonor.old_hok === currentDonor.amount ? currentDonor.amount + ' ש"ח (קיים)' :
                    'תרומה: ' + currentDonor.amount + '(תרומה קודמת: ' + currentDonor.old_hok + ')'} </h4>
              </CardHeader>
              <CardBody className="text-center px-3 pt-0">
                {/* <EditDonorStatus
                    donor={currentDonor}
                    callget={callget}
                  /> */}
                <Row className="pb-3 mx-3">
                  <Col>
                    <Button
                      color="default"
                      className="btn-block"
                      size="md"
                      onClick={() => {
                        setformModal(false);
                        /* setFormDonorModal(true) */
                        toDonorPage();
                        setFormSelectModal(false);
                      }}
                    >
                      כרטיס תורם
                    </Button>
                  </Col>
                </Row>
                <Row className="pb-1 mx-3">
                  <Col>
                    <Button
                      className="btn-block"
                      color="success"
                      size="md"
                      onClick={() => {
                        setformModal(true)
                        setFormDonorModal(false)
                        setFormSelectModal(false)
                      }}
                    >
                      הוסף תרומה לתורם
                    </Button>
                  </Col>
                </Row>
                {/* <Row>
                    <Col>
                      <Button
                        color="info"
                        size="md"
                        className="btn-block"
                        onClick={() => {
                          setNedarimModal(true)
                          setformModal(false)
                          setFormDonorModal(false)
                          setFormSelectModal(false)
                        }}
                      >
                        תרומה באשראי (נדרים)
                      </Button>
                    </Col>
                  </Row> */}
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={formModal}
          backdrop={'static'}
          toggle={() => setformModal(false)}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0">
              <AddLotteryDonation
                donor={currentDonor}
                modalCallback={modalCallback}
                callget={callget}
                type={'add'}
                donation={null}
              />
              {/*  <NedarimIframe
                donor={currentDonor}
              /> */}
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={nedarimModal}
          toggle={() => setNedarimModal(false)}
        >
          <div className="modal-body p-0">
            <Card className="border-0 p-4">
              <NedarimIframe
                donor={currentDonor}
              />
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={formAddModal}
          toggle={() => setFormAddModal(false)}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0">
              <AddDonor
                teamsOptions1={teamsOptions1}
                teamsOptions3={teamsOptions3}
                modalCallback={modalCallbackAddDonor}
                callget={callget}
                donateModalAfterAddDonor={donateModalAfterAddDonor}
              />
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={formDonorModal}
          toggle={() => setFormDonorModal(false)}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0">
              <EditDonor
                donor={currentDonor}
                teamsOptions1={teamsOptions1}
                teamsOptions3={teamsOptions3}
                modalCallback={modalCallbackEditDonor}
                callget={callget}
              />
            </Card>
          </div>
        </Modal>
        <Collapse isOpen={collapse}>
          <UploadXlsx
            tableName={{ title: 'תורמים', table: 'donors' }}
            callget={callget}
          />
        </Collapse>
        <Container fluid>
          <Row className='mh'>
            <div className="col" ref={boxRef}>
              <Card style={{ height: windowSize + "px" }}>
                <CardHeader className="">
                  {user.campaign.permission < 26 ? <Row className="">
                    {user.campaign.meta.status_display == 'false' ||
                      <FormGroup className="form-inline ml-4 mb-2">
                        <label>
                          סטטוס:
                        </label>
                        <Input
                          id="status"
                          size={'sm'}
                          className="mr-1"
                          type="select"
                          name="status"
                          onChange={handleChangeFilter}
                          value={filtersValues.status}
                        >
                          {statusOptions.map((option) => (
                            <option value={option.value} key={option.status}>{option.status}</option>
                          ))}
                          <option value="0" key="s">הכל</option>
                        </Input>
                      </FormGroup>}
                    {(user.campaign.meta.status2_display && user.campaign.meta.status2_display == 'true') &&
                      <FormGroup className="form-inline ml-4 mb-2">
                        <label>
                          {user.campaign.meta.status2_title}
                        </label>
                        <Input
                          id="status2"
                          size={'sm'}
                          className="mr-1"
                          type="select"
                          name="status2"
                          onChange={handleChangeFilter}
                          value={filtersValues.status2}
                        >
                          {textToAarray(user.campaign.meta.status2_values)?.map((option) => (
                            <option value={option} key={option}>{option}</option>
                          ))}
                          <option value="0" key="s">הכל</option>
                        </Input>
                      </FormGroup>}
                    {user.campaign.meta.status3_display &&
                      <FormGroup className="form-inline ml-4 mb-2">
                        <label>
                          {user.campaign.meta.status3_title}
                        </label>
                        <Input
                          id="status3"
                          size={'sm'}
                          className="mr-1"
                          type="select"
                          name="status3"
                          onChange={handleChangeFilter}
                          value={filtersValues.status3}
                        >
                          {textToAarray(campaign.meta.status3_values)?.map((option) => (
                            <option value={option} key={option}>{option}</option>
                          ))}
                          <option value="0" key="s">הכל</option>
                        </Input>
                      </FormGroup>}
                    <FormGroup className="form-inline ml-4 mb-2">
                      <label>
                        מתרים:
                      </label>
                      <Input
                        id="team1"
                        type="select"
                        name="team1"
                        size={'sm'}
                        className="mr-1"
                        onChange={handleChangeFilter}
                        value={filtersValues.team1}
                      >
                        <option value="0" key="s">הכל</option>
                        {teamsOptions1.map((option) => (
                          <option value={option.team} key={option.id}>{option.team}</option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup className="form-inline ml-4 mb-2">
                      <label>
                        קהילה:
                      </label>
                      <Input
                        id="team3"
                        type="select"
                        name="team3"
                        size={'sm'}
                        className="mr-1"
                        onChange={handleChangeFilter}
                        value={filtersValues.team3}
                      >
                        <option value="0" key="s">הכל</option>
                        {teamsOptions3.map((option) => (
                          <option value={option.team} key={option.id}>{option.team}</option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup className="form-inline ml-4 mb-2">
                      <label>
                        חפש:
                      </label>
                      <Input
                        id="text"
                        type="text"
                        name="text"
                        size={'sm'}
                        className="mr-1"
                        onChange={(e) => setText(e.target.value)}
                        onBlur={handleChangeText}
                        value={text}
                        placeholder="חפש בכל השדות"
                      >
                      </Input>
                      <Button
                        className=""
                        color={!filterText ? "default" : "info"}
                        size="sm"
                        onClick={() => callget(0, 'restart')}
                      >
                        <Search />
                      </Button>
                    </FormGroup>
                    <FormGroup className="form-inline ml-4 mb-2">
                      <Button
                        className=""
                        color="default"
                        size="sm"
                        onClick={() => callget(0, 'restart')}
                      >
                        החל מסננים
                        <FunnelFill className='mr-2' />
                      </Button>
                    </FormGroup>
                    {(user.campaign.permission == 1) &&
                      <>
                        <FormGroup className="form-inline ml-4 mb-2">
                          <div className="ml-2">
                            <ExpotCsv url={getUrl('all')}
                              size='sm' color='primary' text='ייצוא לאקסל' fileTitle='תורמים' />
                          </div>
                          {/* <div className="">
                                  <ExpotCsv url={getUrl('columns')}
                                    size='sm' color='primary' text='ייצוא להדפסה' fileTitle='תורמים' />
                                </div> */}
                        </FormGroup>
                      </>
                    }
                    <FormGroup className="form-inline ml-4 mb-2">
                      {donorsCount > 0 && donorsCount + ' רשומות'}
                    </FormGroup>
                  </Row> :
                    ''
                  }
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  {pending && <Loader text="" />}
                  <thead className="thead-light thead-sticky">
                    <tr>
                      <th className='p-0' />
                      <th className='p-0' />
                      {columns.map((option) => (
                        <th key={option.dataField}
                          className={order !== option.dataField ? 'table-sort table-sort-top' : ''}
                          data-sort={option.dataField}

                          onClick={() => { option.dataField !== order ? setOrder(option.dataField) : setOrderdir(orderdir === 'ASC' ? 'DESC' : 'ASC') }}
                        >
                          <span className='ml-1'>{option.text}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {donors.slice(0, donorsTo)?.map((option, i) => (
                      <tr key={option.id + '-' + i}/*  onClick={() => { }} role="button" */>
                        <td key='editbtn' onClick={() => donateModal(i)}
                          className="pl-0 pr-3 hover-row-class"
                          id={"tooltip8884746" + option.id + '-' + i}
                          role="button"
                        >
                          <Coin className="ml-3 text-donors-light" size={16}
                          />
                        </td>
                        <td key='editbtn2' onClick={() => toDonorPage(option.id)}
                          className="px-3 hover-row-class text-center"
                          id={"tooltip106307927" + option.id + '-' + i}
                          role="button"
                        >
                          <PencilSquare
                            className="text-donors-pink" size={16}
                          />
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip106307927" + option.id + '-' + i}
                          >
                            כרטיס תורם
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip8884746" + option.id + '-' + i}
                          >
                            הוסף תרומה
                          </UncontrolledTooltip>
                        </td>
                        {columns.map((col) => (
                          <td key={option.id + col.dataField + i}
                            onClick={() => { if (editField !== option.id + col.dataField) { setEditField(option.id + col.dataField) } }}>
                            {col.edit && editField === (option.id + col.dataField) ?
                              <FormGroup className="form-inline m-0 p-0">
                                <Input
                                  id="status"
                                  size={'sm'}
                                  type="select"
                                  name="status"
                                  onChange={handleChangeFilter}
                                  value={option[col.dataField]}
                                >
                                  {statusOptions.map((soption) => (
                                    <option value={soption.value} key={soption.status}>{soption.status}</option>
                                  ))}
                                </Input>
                              </FormGroup>
                              : option[col.dataField]
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                    {donors.length < amount ||
                      <tr className='w-100 text-center mx-auto align-items-center bg-donors-light p-0'
                        onClick={() => showMore()}
                        role="button">
                        <td colspan="15" className='p-2'>
                          <Button
                            outline color="primary" type="button">
                            <ChevronDown className='ml-1' />
                            טען עוד
                          </Button>
                        </td>
                      </tr>}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Donors;