import React, { useState, useContext, useEffect } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
import { UserContext } from "context/userContext";
import { CampaignContext } from "context/campaignContext";
//services
import addUser from 'services/addUser';
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import getTeams from 'services/getTeams';

function AddUser({ modalCallback, callget }) {
  const { user } = useContext(UserContext);
  const { campaign } = useContext(CampaignContext);
  const [formValues, setFormValues] = useState({
    campaign: campaign.id,
    type: 'leader',
    display_name: '',
    user_name: '',
    permission: 35,
    team: 0,
    team_type: 1,
  });
  const [pending, setPending] = useState(false);
  const [teams1, setTeams1] = useState(false);
  const [teams3, setTeams3] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTeam();
  }

  function setTeam() {
    setPending(true);
    addUser(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('שגיאה: ' + data, 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            showAlert('משתמש נוסף בהצלחה', 'success')
            callget();
          }
        })
      .catch((err) => {
        showAlert('service error', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function callgetTeams(type) {
    getTeams(campaign.id, type)
      .then(
        (response) => {
          function compare(a, b) {
            if (a.team > b.team) {
              return 1;
            }
            if (a.team < b.team) {
              return -1;
            }
            return 0;
          }
          response.sort(compare);
          if (type === 1) {
            setTeams1(response);
          }
          else {
            setTeams3(response);
          }
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת תקשורת', 'danger')
      }
      );
  }

  useEffect(() => {
    callgetTeams(1)
    callgetTeams(3)
  }, [])

  return (
    <>
      {pending && <Pending />}
      {user.campaign.permission > 7 ?
        <div>
          <h1 className="text-center m-4">הוספת משתמש</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          <CardHeader className="text-center mb-0">
            <h1>הוספת משתמש</h1>
          </CardHeader>
          <CardBody className='mt-0 pt-0'>
            <Form role="form" onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="display_name"
                    >
                      שם
                    </label>
                    <Input
                      id="display_name"
                      type="text"
                      name="display_name"
                      placeholder="שם מלא"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.display_name}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="user_name"
                    >
                      {'אימייל (שם משתמש)'}
                    </label>
                    <Input
                      id="user_name"
                      type="email"
                      name="user_name"
                      placeholder="שם משתמש @ אימייל"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.user_name}
                    />
                    <span className='h5 text-gray'>קישור להגדרת סיסמה ישלח למייל המשתמש</span>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-4">
                    <label
                      className="form-control-label"
                      htmlFor="type"
                    >
                      סוג
                    </label>
                    <Input
                      id="type"
                      type="select"
                      name="type"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.type}
                    >
                      {user.role === 'administrator' && <option value='agent' key={3}>סוכן</option>}
                      <option value='leader' key={1}>מנהל</option>
                      <option value='team' key={2}>מתרים</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="permission"
                    >
                      הרשאה {formValues.type === 'team' && '(לתורמים המשויכים)'}
                    </label>
                    <Input
                      id="permission"
                      type="select"
                      name="permission"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.permission}
                    >
                      {formValues.type !== 'team' ?
                        <>
                          <option value={35} key={35}>הוספת תרומות אשראי</option>
                          <option value={30} key={30}>+ הוספת תרומות מזומן</option>
                          <option value={25} key={25}>+ עריכת תרומות</option>
                          <option value={20} key={20}>+ עריכת תורמים</option>
                          <option value={15} key={15}>+ הוספת תורמים</option>
                          <option value={10} key={10}>+ הגדרת מתרימים</option>
                          <option value={7} key={7}>+ הגדרת משתמשים</option>
                          <option value={4} key={4}>+ הגדרות קמפיין</option>
                          {user.role === 'administrator' &&
                            <>
                              <option value={2} key={2}>+ הגדרות מלאות</option>
                              <option value={1} key={1}>מנהל</option>
                            </>
                          }
                        </>
                        :
                        <>
                          <option value={35} key={35}>הוספת תרומות אשראי</option>
                          <option value={30} key={30}>+ הוספת תרומות מזומן</option>
                          <option value={20} key={20}>+ עריכת תורמים</option>
                          <option value={15} key={15}>+ הוספת תורמים</option>
                        </>
                      }
                    </Input>
                  </FormGroup>
                </Col>
                {formValues.type === 'team' &&
                  <>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="team_type"
                        >
                          סוג מתרים
                        </label>
                        <Input
                          id="team_type"
                          type="select"
                          name="team_type"
                          onChange={handleChange}
                          onBlur={handleChange}
                          required
                          value={formValues.team_type}
                        >
                          <option value='3' key={3}>קהילה</option>
                          <option value='1' key={1}>מתרים</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="team"
                        >
                          {formValues.team_type === '3' ? 'קהילה משויכת:' : 'מתרים משויך:'}
                        </label>
                        <Input
                          id="team"
                          type="select"
                          name="team"
                          onChange={handleChange}
                          onBlur={handleChange}
                          required
                          value={formValues.team}
                        >
                          <option value={0} key={0}>בחר</option>
                          {formValues.team_type === '3' ?
                            teams3.map((option) => (
                              <option value={option.team} key={option.id}>{option.team}</option>
                            ))
                            :
                            teams1.map((option) => (
                              <option value={option.team} key={option.id}>{option.team}</option>
                            ))
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  </>}
              </Row>
              <Button
                block
                color="info"
                className="bg-info"
                type="submit"
              >
                הוסף חדש
              </Button>
            </Form>
          </CardBody>
        </div>}
    </>
  );
}

export default AddUser;