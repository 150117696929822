export default function getTeams(campaign, type) {
    let url = 'https://lottery.donors.co.il/wp-json/shpro/v1/teams/type/'+ type + '/campaign/'+ campaign ;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}


