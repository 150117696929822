export default function getUser(id, campaign, type) {

    const getOptions = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
    };

    let urls = [
        'https://lottery.donors.co.il/wp-json/donors/v1/campaign/' + campaign,
        'https://lottery.donors.co.il/wp-json/shpro/v1/campaign/' + campaign + '/summary'
    ]

    return Promise.all(urls.map(u => fetch(u, getOptions))).then(responses =>
        Promise.all(responses.map(res => res.json()))
    ).then(res => {
        return res;
    })
}


