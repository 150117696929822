import { createContext, useEffect, useState, useContext } from 'react';
import { UserContext } from "context/userContext";


export const CampaignContext = createContext();

const CampaignContextProvider = (props) => {

    const { dataUrl, headers } = props;
    const [isCampaign, setIsCampaign] = useState(true);
    const [campaign, setCampaign] = useState({});
    const options = { credentials: 'include', mode: 'cors', headers };
    const { user } = useContext(UserContext)

    useEffect(async () => {
        if (user) {
            await fetch(dataUrl + `campaign/${user.campaign.id}`, options)
                .then(res => res.json())
                .then(res => {
                    if (res.success) return res.data
                    else throw new Error("couldn't fetch current user")
                })
                .then(data => {
                    setCampaign(data[0])
                })
                .catch(err => console.log(err))
        }
        setIsCampaign(false);
    }, [user])


    return (
        <CampaignContext.Provider value={{ campaign, setCampaign, isCampaign, dataUrl, headers }} >
            {props.children}
        </CampaignContext.Provider>
    );
}

export default CampaignContextProvider;