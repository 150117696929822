import DashboardAchim from "views/pages/dashboards/DashboardAchim.js";
import Campaigns from "views/Campaigns";
import Campaign from "views/pages/forms/Campaign.js";
import Donors from "views/pages/tables/Donors.js";
import DonorPage from "views/pages/tables/Donor.js";
import Donations from "views/pages/tables/Donations.js";
import DonationsMerge from "views/pages/tables/DonationsMerge.js";
import AddOffline from "views/pages/forms/AddOffline.js";
import Teams from "views/pages/tables/Teams.js";
import Users from "views/pages/tables/Users.js";
import { PersonCheck, PlusCircle, House, People, Coin, Diagram2, Megaphone, PersonFill, Grid, PersonCircle } from 'react-bootstrap-icons';
import Donor from "views/pages/tables/Donor";

const user_routes = [
  {
    path: "/home",
    name: "דף הבית",
    icon: <House size={19} className="mb-1" />,
    component: DashboardAchim,
    layout: "/app",
  },
  {
    path: "/donors",
    name: "תורמים",
    icon: <People size={19} className="mb-1" />,
    component: Donors,
    layout: "/app",
  },
  /*
  {
    path: "/donations",
    name: "תרומות",
    icon: <Coin size={19} className="mb-1" />,
    component: Donations,
    layout: "/app",
  },
  {
    path: "/add",
    name: "הוספת תרומה",
    icon: <PlusCircle size={19} className="mb-1" />,
    component: AddOffline,
    layout: "/action",
  },
  {
    path: "/donor/:id",
    name: "תורם",
    icon: <PersonFill size={19} className="mb-1" />,
    component: DonorPage,
    layout: "/action",
  }, */
];

const admin_routes = [
  {
    path: "/home",
    name: "דף הבית",
    icon: <House size={19} className="mb-1" />,
    component: DashboardAchim,
    layout: "/app",
  },
  {
    path: "/campaigns",
    name: "הגרלות",
    icon: <Grid size={19} className="mb-1" />,
    component: Campaigns,
    layout: "/app",
  },
  {
    path: "/campaign",
    name: "הגרלה",
    icon: <Megaphone size={19} className="mb-1" />,
    component: Campaign,
    layout: "/app",
  },
  {
    path: "/users",
    name: "משתמשים",
    icon: <PersonCircle size={19} className="" />,
    component: Users,
    layout: "/app",
  },
  {
    path: "/teams",
    name: "מתרימים",
    icon: <Diagram2 size={21} className="mb-1" />,
    component: Teams,
    layout: "/app",
  },
  {
    path: "/donors",
    name: "תורמים",
    icon: <People size={19} className="mb-1" />,
    component: Donors,
    layout: "/app",
  },
  {
    path: "/donor/:id",
    name: "תורם חדש",
    icon: <PersonFill size={19} className="mb-1" />,
    component: DonorPage,
    layout: "/app",
  },
  {
    path: "/donations",
    name: "תרומות",
    icon: <Coin size={19} className="mb-1" />,
    component: Donations,
    layout: "/app",
  },
  /* {
    path: "/merge",
    name: "שיוך תרומות",
    icon: <PersonCheck size={19} className="mb-1" />,
    component: DonationsMerge,
    layout: "/app",
  }, */
  {
    path: "/add",
    name: "הוספת תרומה",
    icon: <PlusCircle size={19} className="mb-1" />,
    component: AddOffline,
    layout: "/action",
  },
];

export { user_routes, admin_routes };