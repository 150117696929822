import React, { useState, useContext, useRef, useEffect } from 'react';
// react plugin that prints a given react component
//context
import { UserContext } from "context/userContext";
import DonationsContext from 'context/DonationsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getTeamDonations from 'services/getTeamDonations';
import getAllDonations from 'services/getAllDonations';
import getTeams from 'services/getTeams';
// nodejs library that concatenates classes
import classnames from "classnames";
//react-to-print
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  Modal,
} from "reactstrap";
import { Coin } from 'react-bootstrap-icons';
// core components
import EditDonation from "views/pages/forms/EditDonation.js";
import Loader from "components/custom/Loader";
import AddLotteryDonation from '../forms/AddLotteryDonation';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        הצג {" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option key="10" value="10">10</option>
            <option key="25" value="25">25</option>
            <option key="50" value="50">50</option>
            <option key="100" value="100">100</option>
            <option key="250" value="250">250</option>
          </select>
        }{" "}
        שורות
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function Donations() {
  const [alert, setAlert] = useState(null);
  const componentRef = useRef(null);
  const { user } = useContext(UserContext);
  const { donations, setDonations } = useContext(DonationsContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [formModal, setformModal] = useState(false);
  const [currentDonation, setCurrentDonation] = useState({});
  const [team1, setTeam1] = useState('');
  const [team3, setTeam3] = useState('');
  const [teamsOptons1, setTeamsOptons1] = useState([]);
  const [teamsOptons3, setTeamsOptons3] = useState([]);
  const [pending, setPending] = useState(false);

  const handleChange1 = (e) => {
    setTeam1(e.target.value);
  }

  const handleChange3 = (e) => {
    setTeam3(e.target.value);
  }

  function modalCallback(status) {
    setformModal(status)
  }

  function callTeam(team, type) {
    if (team == 'בחר') return callgetAll(4000, 0);
    type === 3 ? setTeam1('בחר') : setTeam3('בחר');

    getTeamDonations(team, type, user.campaign.id)
      .then(
        (response) => {
          setDonations(response);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('העדכון נכשל', 'danger')
      }
      );
  }

  function callget() {
    setPending(true);
    getTeamDonations(user.team, user.type, user.campaign.id)
      .then(
        (response) => {
          setDonations(response);
          setPending(false);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('העדכון נכשל', 'danger')
        setPending(false);
      }
      );
  }

  function callgetAll(limit, offset) {
    setPending(true);
    getAllDonations(user.campaign.id, limit, offset)
      .then(
        (response) => {
          setDonations(response);
          setPending(false);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('העדכון נכשל', 'danger')
        setPending(false);
      }
      );
  }

  function callgetTeams(type) {
    getTeams(user.campaign.id, type)
      .then(
        (response) => {
          function compare(a, b) {
            if (a.team > b.team) {
              return 1;
            }
            if (a.team < b.team) {
              return -1;
            }
            return 0;
          }
          response.sort(compare);
          if (type === 1) {
            setTeamsOptons1(response);
          }
          else {
            setTeamsOptons3(response);
          }
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('העדכון נכשל', 'danger')
      }
      );
  }

  function callgetUpdate() {
    if (user.type == 1 || user.type == 3) {
      callget();
    }
    else {
      callgetAll(4000, 0);
    }
  }

  useEffect(() => {
    document.title = `donors - תרומות`;
    if (user.type == 1 || user.type == 3) {
      callget();
    }
    else {
      callgetAll(4000, 0);
    }
    callgetTeams(1)
    callgetTeams(3)
  }, [])


  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="המידע הועתק ללוח!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        ניתן להדביק באקסל או בכל מקום
      </ReactBSAlert>
    );
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentDonation(row)
      setformModal(true)
    }
  };

  return (
    <>
      {user.type == 4 ?
        <div>
          <h1 className="text-center m-4">תרומות</h1>
          <h4 className="text-center m-4">התרומות יוצגו בקרוב</h4>
        </div> :
        <div>
          {alert}
          <h1 className="text-center m-3 text-donors-pink"><Coin size={22} /> תרומות</h1>
          {/* <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formModal}
            toggle={() => setformModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <EditDonation
                  donation={currentDonation}
                  modalCallback={modalCallback}
                  callget={callgetUpdate}
                />
              </Card>
            </div>
          </Modal> */}
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={formModal}
            /* backdrop={'static'} */
            toggle={() => setformModal(false)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary border-0">
                <EditDonation
                  donor={{ name: '', id: '', phone: '', address: '', email: '' }}
                  modalCallback={modalCallback}
                  callget={callgetAll}
                  donation={currentDonation}
                  type={'edit'}
                />
              </Card>
            </div>
          </Modal>
          <Container fluid>
            <Row>
              <div className="col">
                <Card>
                  <ToolkitProvider
                    data={donations}
                    keyField="id"
                    columns={[
                      {
                        dataField: "id",
                        text: "מזהה",
                        sort: true,
                      },
                      {
                        dataField: "name",
                        text: "שם",
                        sort: true,
                      },
                      {
                        dataField: "amount",
                        text: "סכום",
                        sort: true,
                        sortFunc: (a, b, order, dataField, rowA, rowB) => {
                          if (order === 'asc') {
                            return b - a;
                          }
                          return a - b; // desc
                        }
                      },
                      {
                        dataField: "methods_Payment",
                        text: "אמצעי",
                        sort: true,
                      },
                      {
                        dataField: "Tashloumim",
                        text: "תשלומים",
                        sort: true,
                      },
                      {
                        dataField: "KevaId",
                        text: "כרטיסים",
                        sort: true,
                      },
                      {
                        dataField: "terminal",
                        text: "מסוף",
                        sort: true,
                      },
                      {
                        dataField: "user",
                        text: "מעדכן",
                        sort: true,
                      },
                      {
                        dataField: "Comments",
                        text: "הערות",
                        sort: true,
                      },
                      {
                        dataField: "create_time",
                        text: "תאריך",
                        sort: true,
                      },
                      {
                        dataField: "team1",
                        text: "מתרים",
                        sort: true,
                      },
                      {
                        dataField: "team3",
                        text: "קהילה",
                        sort: true,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          {/*   <Row className="mb-3">
                            {user.role < 4 &&
                              <>
                                <Col >
                                  <Button
                                    className=""
                                    color="info"
                                    size="md"
                                    onClick={() => callgetAll(3000, 0)}
                                  >
                                    בטל סינונים
                                  </Button>
                                </Col>
                              </>
                            }
                          </Row> */}
                          {user.campaign.permission < 30 && <Row className="">
                            <Col xs={12} sm={4}>
                              <Row className="d-flex flex-row form-inline">
                                <label>
                                  מתרים:
                                </label>
                                <Input
                                  id="methods_Payment"
                                  size={'sm'}
                                  type="select"
                                  name="methods_Payment"
                                  onChange={handleChange1}
                                  onBlur={handleChange1}
                                  value={team1}
                                >
                                  <option value="בחר" key="s">בחר</option>
                                  {teamsOptons1.map((option) => (
                                    <option value={option.team} key={option.id}>{option.team}</option>
                                  ))}
                                </Input>
                                <Button
                                  color="default"
                                  size={'sm'}
                                  onClick={() => callTeam(team1, 1)}
                                >
                                  סינון מתרים
                                </Button>
                              </Row>
                            </Col>
                            <Col xs={12} sm={4}>
                              <Row className="d-flex flex-row form-inline">
                                <label>
                                  קהילה:
                                </label>
                                <Input
                                  id="methods_Payment"
                                  type="select"
                                  size={'sm'}
                                  name="methods_Payment"
                                  onChange={handleChange3}
                                  onBlur={handleChange3}
                                  value={team3}
                                >
                                  <option value="בחר" key="s">בחר</option>
                                  {teamsOptons3.map((option) => (
                                    <option value={option.team} key={option.id}>{option.team}</option>
                                  ))}
                                </Input>
                                <Button
                                  color="default"
                                  size={'sm'}
                                  onClick={() => callTeam(team3, 3)}
                                >
                                  סינון קהילה
                                </Button>
                              </Row>
                            </Col>
                            <Col xs={12} sm={4}>
                              <div
                                id="datatable-basic_filter"
                                className="float-right"
                              >
                                <label>
                                  חפש:
                                  <SearchBar
                                    className="form-control-md"
                                    placeholder="חיפוש חכם"
                                    {...props.searchProps}
                                    size={'sm'}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>}
                        </Container>
                        {pending && <Loader text="" />}
                        <BootstrapTable
                          ref={componentRef}
                          rowEvents={rowEvents}
                          rowClasses="hover-row-class"
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          id="react-bs-table"
                          defaultSorted={[{
                            dataField: 'id',
                            order: 'desc'
                          }]}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            </Row>
          </Container>
        </div>}
    </>
  );
}

export default Donations;
