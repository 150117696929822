export default function getTeamDonorsCount(team, type, campaign) {
    let url = 'https://lottery.donors.co.il/wp-json/shpro/v1/donors/count/type/'+ type + '?team=' + team + '& campaign=' + campaign;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}


