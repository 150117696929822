import React, { useContext } from 'react';
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
//context
import SidenavOpenContext from 'context/SidenavOpenContext';
import { UserContext } from "context/userContext";
// core components
import AdminNavbar from "components/Navbars/AdminNavbarRtl.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { user_routes as routes, admin_routes } from "routes.js";

function AppLayout() {
  const { sidenavOpen, setSidenavOpen } = useContext(SidenavOpenContext);
  const { user } = useContext(UserContext);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.body.classList.add("rtl");
    document.documentElement.classList.add("rtl");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("rtl");
      document.documentElement.classList.remove("rtl");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      /* if (prop.collapse) {
        return getRoutes(prop.views);
      } */
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const roles = ['administrator', 'manager','agent', 'leader' ];

  return (
    <>
      <Sidebar
        routes={roles.includes(user.campaign.role) && user.campaign.permission < 25 ?
          admin_routes.filter(function (route) {
            return route.name !== 'תורם חדש';
          })
          : routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        rtlActive
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/180w.png").default,
            imgAlt: "...",
          }}
          theme="dark"
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(admin_routes)}
          <Redirect to="/home" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default AppLayout;
