/* export default function getNewCampaign(campaign) {
    let token = localStorage.getItem("donors-jwt");

    const getOptions = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    let urls = [
        `https://lottery.donors.co.il/wp-json/donors/v1/campaign/${campaign}/user`,
        'https://lottery.donors.co.il/wp-json/donors/v1/campaigns/user'
    ]

    return Promise.all(urls.map(u => fetch(u, getOptions))).then(responses =>
        Promise.all(responses.map(res => res.json()))
    ).then(res => {
        return res;
    })
} */

export default function getNewCampaign(campaign) {
    const token = localStorage.getItem("donors-jwt");
    const url = `https://lottery.donors.co.il/wp-json/donors/v1/switchcampign/campaign/${campaign}`;
    const options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}