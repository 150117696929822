export default function updateCampaignField(campaign, name, value, meta) {
    let url = `https://lottery.donors.co.il/wp-json/donors/v1/campaign/${campaign}/updatefield`;
    if (meta == true) url += '/meta'
    const token = localStorage.getItem("donors-jwt");

    let formData = new FormData();
    formData.append('name', name);
    formData.append('value', value);

    const options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        },
        body: formData
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}
