import React, { useState, useContext } from 'react';
//context
import { CampaignContext } from "context/campaignContext";
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import postDonation from 'services/postDonation';
import postNedarim from 'services/postNedarim';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function AddOfflineForm({ donor, modalCallback, callget }) {
  const { name: fullName, id, phone, address } = donor
  const { user } = useContext(UserContext);
  const { campaign } = useContext(CampaignContext);
  const { campaign_type } = campaign;
  const [formValues, setFormValues] = useState({
    donor: id,
    amount: '',
    terminal: 'דשבורד',
    user: user.team,
    campaign: user.campaign,
    insight: true,
    Comments: '',
    Tashloumim: campaign_type === 'HK' ? 999 : 1,
    Zeout: '',
    methods_Payment: 'אשראי',
    approved: user.type == 2 ? 1 : 0
  });
  const [pending, setPending] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formValues.methods_Payment === 'אשראי') {
      setNedarim();
    } else {
      setDonation();
    }
  }

  function setNedarim() {
    setPending(true);
    postNedarim(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            const src = `https://lottery.donors.co.il/nedarim/?mosad=${campaign.mosad}&apiValid=${campaign.api_valid}&paymentType=${campaign.campaign_type}&currency=1&zeout=${formValues.Zeout}&fullName=${fullName}&street=${address}&phone=${phone}&mail=&amount=${formValues.amount}&tashlumim=${formValues.Tashloumim}&comment=${data}`
            window.open(src, '_top');
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function setDonation() {
    setPending(true);
    postDonation(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            callget(250, 0);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      <CardHeader className="text-center">
        <h1>הוספת תרומה</h1>
        <h4>לתורם {fullName} - {id}</h4>
      </CardHeader>
      <CardBody>
        <Form role="form" onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <label
              className="form-control-label"
              htmlFor="methods_Payment"
            >
              אמצעי תשלום:
            </label>
            {user.permission == 3 ?
              <Input
                id="methods_Payment"
                type="select"
                name="methods_Payment"
                onChange={handleChange}
                onBlur={handleChange}
                required
                disabled
                value={formValues.methods_Payment}
              >
                <option key="2">אשראי</option>
              </Input>
              :
              <Input
                id="methods_Payment"
                type="select"
                name="methods_Payment"
                onChange={handleChange}
                onBlur={handleChange}
                required
                value={formValues.methods_Payment}
              >
                {campaign_type === 'HK' ?
                  <>
                    <option key="2">אשראי</option>
                    <option key="3">הוק בנקאי</option>
                    <option key="4">העלאת הוק בנקאי קיים</option>
                    <option key="5">העלאת הוק אשראי קיים</option>
                    <option key="6" >מזומן</option>
                    <option key="7" >אחר</option>
                  </> :
                  <>
                    <option key="2">מזומן</option>
                    <option key="3">שיק</option>
                    <option key="4">העברה בנקאית</option>
                    <option key="5">אשראי</option>
                    <option key="6">אשראי במסופון</option>
                    <option key="7" >אשראי קיים</option>
                  </>
                }
              </Input>}
          </FormGroup>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                  htmlFor="amount"
                >
                  {campaign_type === 'HK' ? 'סכום לחודש' : 'סך התרומה'}

                </label>
                <Input
                  id="amount"
                  type="number"
                  name="amount"
                  placeholder={campaign_type === 'HK' ? 'סכום לחודש' : 'סך התרומה'}
                  onChange={handleChange}
                  onBlur={handleChange}
                  required
                  value={formValues.amount}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                  htmlFor="Tashloumim"
                >
                  תשלומים
                </label>
                <Input
                  id="Tashloumim"
                  type="number"
                  name="Tashloumim"
                  placeholder="תשלומים"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={formValues.Tashloumim}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {formValues.methods_Payment === 'אשראי' &&
              <Col md="12">
                <FormGroup className="mb-1">
                  <label
                    className="form-control-label"
                    htmlFor="Zeout"
                  >
                    תעודת זהות
                  </label>
                  <Input
                    id="Zeout"
                    type="text"
                    name="Zeout"
                    placeholder="תעודת זהות"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.Zeout}
                  />
                </FormGroup>
              </Col>
            }
            {(formValues.methods_Payment === 'הוק בנקאי' || formValues.methods_Payment === 'אחר') &&
              <Col md="12">
                <FormGroup className="mb-1">
                  <Input
                    id="insight"
                    type="checkbox"
                    name="insight"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={formValues.insight}
                    required
                  />
                  <label
                    className="form-control-label mr-4"
                    htmlFor="insight"
                  >
                    טופס הוק חתום / פרטי אשראי אצלי
                  </label>
                </FormGroup>
              </Col>
            }
            <Col md="12">
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                  htmlFor="Comments"
                >
                  הערות
                </label>
                <Input
                  id="Comments"
                  rows="1"
                  type="textarea"
                  name="Comments"
                  placeholder="פרטי התרומה"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={formValues.Comments}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button
            block
            color={formValues.methods_Payment === 'אשראי' ? 'darker' : 'info'}
            type="submit"
          >
            {formValues.methods_Payment === 'אשראי' ? 'המשך לפרטי אשראי' : 'הוסף תרומה'}
          </Button>
        </Form>
      </CardBody>
    </>
  );
}

export default AddOfflineForm;
