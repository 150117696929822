import React from 'react';

// reactstrap components
import {
  Card,
  Row,
  Col,
  CardHeader,
  Table,

} from "reactstrap";

function Summary2({ summary2 }) {

  function separator(numb) {
    if (!numb) return numb;
    let str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }


  function getSummary() {
    let a = []
    for (let i = 0; i < summary2.length; i = i + 3) {
      a.push(
        <>
          <tr key={i}>
            <th>{summary2[i].title}</th>
            <td>{summary2[i].total}</td>
            <td>{summary2[i].donors}</td>
            <td>{summary2[i].total / summary2[i].donors}</td>
          </tr>
          {(i + 1) < summary2.length &&
            <>
              <tr key={i + 't'}>
                <th>{summary2[i + 1].title}</th>
                <td>{summary2[i + 1].total}</td>
                <td>{summary2[i + 1].donors}</td>
                <td>{summary2[i + 1].total / summary2[i + 1].donors}</td>
              </tr>
              <tr key={i + 'tt'} className="thead-lighter">
                <th>{summary2[i + 2].title}</th>
                <td>{summary2[i + 2].total}</td>
                <td>{summary2[i + 2].donors}</td>
                <td>{summary2[i + 2].total / summary2[i + 2].donors}</td>
              </tr>
            </>
          }
        </>
      );
    }
    return a;
  }

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">פילוח תרומות</h3>
            </div>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">טווח</th>
              <th scope="col">סכום</th>
              <th scope="col">תורמים</th>
              <th scope="col">ממוצע</th>
            </tr>
          </thead>
          <tbody>
            {getSummary()}
          </tbody>
        </Table>
      </Card>
    </>
  );
}

export default Summary2;
