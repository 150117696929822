import React, { useState, useContext, useRef, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
//context
import { UserContext } from "context/userContext";
import DonationsContext from 'context/DonationsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
import DonorsContext from 'context/DonorsContext';
//services
import getDonorDonations from 'services/getDonorDonations';
import getTeams from 'services/getTeams';
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  Modal,
} from "reactstrap";
// core components
import EditDonation from "views/pages/forms/EditDonation.js";
import EditDonorCard from "views/pages/forms/EditDonorCard.js";
import Loader from "components/custom/Loader";
import AddLotteryDonation from '../forms/AddLotteryDonation';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        הצג {" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option key="10" value="10">10</option>
            <option key="25" value="25">25</option>
            <option key="50" value="50">50</option>
            <option key="100" value="100">100</option>
            <option key="250" value="250">250</option>
          </select>
        }{" "}
        שורות
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function Donor() {
  const [alert, setAlert] = useState(null);
  const componentRef = useRef(null);
  const { user } = useContext(UserContext);
  const { donations, setDonations } = useContext(DonationsContext);
  const { donors, setDonors } = useContext(DonorsContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [formModal, setformModal] = useState(false);
  const [donor, setDonor] = useState({});
  const [currentDonation, setCurrentDonation] = useState({});
  const [teamsOptons1, setTeamsOptons1] = useState([]);
  const [teamsOptons3, setTeamsOptons3] = useState([]);
  const [pending, setPending] = useState(false);

  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const ID = pathArray[2];

  function modalCallback(status) {
    setformModal(status)
  }

  function callget() {
    setPending(true);
    getDonorDonations(ID)
      .then(
        (response) => {
          setDonor(response[0][0]);
          setDonations(response[1]);
          setPending(false);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת תקשורת', 'danger')
        setPending(false);
      }
      );
  }

  function callgetTeams(type) {
    getTeams(user.campaign.id, type)
      .then(
        (response) => {
          function compare(a, b) {
            if (a.team > b.team) {
              return 1;
            }
            if (a.team < b.team) {
              return -1;
            }
            return 0;
          }
          response.sort(compare);
          if (type === 1) {
            setTeamsOptons1(response);
          }
          else {
            setTeamsOptons3(response);
          }
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert('שגיאת תקשורת', 'danger')
      }
      );
  }

  function callgetUpdate() {
    callget();
  }

  useEffect(() => {
    if (donors.length) {
      let findDonor = donors.find(({ id }) => id == ID);
      if (findDonor) setDonor(findDonor);
    }
    callget();
    callgetTeams(1)
    callgetTeams(3)
  }, [])


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentDonation(row)
      setformModal(true)
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        backdrop={'static'}
        toggle={() => setformModal(false)}
      >
      <div className="modal-body p-0">
        <Card className="bg-secondary border-0">
          <EditDonation
            donor={donor}
            modalCallback={modalCallback}
            callget={callget}
            donation={currentDonation}
            type={'edit'}
          />
        </Card>
      </div>
    </Modal>
      <Container fluid className='p-0'>
        <EditDonorCard
          donor={donor}
          teamsOptions1={teamsOptons1}
          teamsOptions3={teamsOptons3}
          callget={callget}
        />
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={donations}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "מזהה",
                    sort: true,
                  },
                  {
                    dataField: "name",
                    text: "שם",
                    sort: true,
                  },
                  {
                    dataField: "amount",
                    text: "סכום",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "methods_Payment",
                    text: "אמצעי",
                    sort: true,
                  },
                  {
                    dataField: "Tashloumim",
                    text: "תשלומים",
                    sort: true,
                  },
                  {
                    dataField: "KevaId",
                    text: "כרטיסים",
                    sort: true,
                  },
                  {
                    dataField: "terminal",
                    text: "מסוף",
                    sort: true,
                  },
                  {
                    dataField: "user",
                    text: "מעדכן",
                    sort: true,
                  },
                  {
                    dataField: "Comments",
                    text: "הערות",
                    sort: true,
                  },
                  {
                    dataField: "create_time",
                    text: "תאריך",
                    sort: true,
                  },
                  {
                    dataField: "team1",
                    text: "מתרים",
                    sort: true,
                  },
                  {
                    dataField: "team3",
                    text: "קהילה",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        תרומות לתורם:
                      </Row>
                    </Container>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      ref={componentRef}
                      rowEvents={rowEvents}
                      rowClasses="hover-row-class"
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'id',
                        order: 'desc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Donor;
