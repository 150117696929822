import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
//context
import { CampaignContext } from "context/campaignContext";
import Pending from 'components/custom/Pending';

function NedarimIframe({ props }) {
  const { donor,
    Zeout,
    fullName,
    address,
    email,
    phone,
    amount,
    sum,
    donation,
    Tashloumim,
    paymentType,
    packages,
    tickets,
    Comments } = props;
  /* const note = JSON.stringify({
    donor: donor,
    Comments: Comments,
    packages: packages,
    tickets: tickets,
  }) */
  const { campaign } = useContext(CampaignContext);
  const [src, setSrc] = useState('');
  const [load, setLoad] = useState(false);

  const history = useHistory();

  function toDonorPage(currentDonorId) {
    let path = `donor/${currentDonorId}`;
    history.push({
      search: '?zeout=blue'
    })
  }
  useEffect(() => {
    //setLoad(true);
    //toDonorPage();
    setSrc(`https://lottery.donors.co.il/nedarim/?groupe='lottors'&mosad=${campaign.mosad}&apiValid=${campaign.api_valid}&paymentType=${paymentType}&currency=${1}&zeout=${Zeout}&fullName=${fullName}&street=${address}&phone=${phone}&mail=${email}&amount=${paymentType === 'HK' ? amount : sum}&tashlumim=${Tashloumim}&comment=${donation}`
    );
  }, [campaign])

  return (
    <>
      {!src ||
        <div>
          <iframe
            id="NedarimFrame"
            src={src}
            style={{ width: '100%', height: '415px', border: 'none' }}
          >
          </iframe>
        </div>
      }
    </>
  )
}


export default NedarimIframe;