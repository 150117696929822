

const PageNotFound = () => {
    return ( 
        <div className="page-header bg-white"
        /* style={{ backgroundImage: "url(" + require("assets/img/sections/section-header-2.jpg").default + ")" }} */
        >
                        <div className="filter" />

            <div className="content-center">
            <h1 className="text-white">העמוד המבוקש לא נמצא</h1>
            </div>
        </div>
     );
}
 
export default PageNotFound;