import React, { useState, useContext } from 'react';
// reactstrap components
import {
  Button,
} from "reactstrap";

function ExpotCsv({ url, size, color, text, fileTitle }) {

  function download() {
    let donations_json = [];

    const getData = async () => {

      let token = localStorage.getItem("token");
      let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        /* headers: {
          'Authorization': 'Bearer ' + token
        } */
      };

      try {
        const res = await fetch(url, options);
        let data = await res.json();
        return data;
      } catch {
        console.log('catch');
        return false; //??
      }
    }

    app()
    async function app() {
      donations_json = await getData();
      download1()
    }

    function convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {

          if (line != '') line += ',';

          let text = array[i][index];
           if (text) text = text.replaceAll(",", ".");
           if (text) text = text.replaceAll("\r", ".");
           if (text) text = text.replaceAll("\n", ".");

          line += text;
        }

        str += line + '\r\n';
      }

      return str;
    }

    function exportCSVFile(headers, items) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

      var link = window.document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv));
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    function download1() {

      let headers = {}
      for (const property in donations_json[0]) {
        headers = {
          ...headers,
          [property]: property
        }
      }

      exportCSVFile(headers, donations_json);
    }
  }

  return (
    <>
      <Button
        block
        color={color}
        size={size}
        type="button"
        onClick={() => download()}
      >
        {text}
      </Button>
    </>
  );
}

export default ExpotCsv;
