import React from 'react';
// reactstrap components
import {
  Row,
} from "reactstrap";

function Pending() {

  const imgStyle = {
    maxHeight: 128,
    maxWidth: 160
  }


  return (
    <>
      <Row className="pending position-absolute w-100 h-100 m-0 p-0 align-content-center justify-content-center">
        <Row className="w-100 p-0 m-0 justify-content-center">
          <img
            alt="load"
            className=""
            style={imgStyle}
            src={require("assets/img/brand/nefesh to achim.svg").default}
          />

        </Row>
        <Row className="p-0 m-0 w-100 justify-content-center">
          <h2 className="load-text">...donors</h2>
        </Row>
      </Row>
    </>
  );
}

export default Pending;
