export default function addCampaign(data) {
    const url = `https://lottery.donors.co.il/wp-json/donors/v1/campaign/${data.campaign}/user`;
    const token = localStorage.getItem("donors-jwt");

    let formData = new FormData();
    for (const name in data) {
        formData.append(name, data[name]);
    }

    const options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        },
        body: formData
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}