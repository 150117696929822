import React from 'react';
// reactstrap components
import {
  Row,
} from "reactstrap";

function Loader({text}) {

  const imgStyle = {
    maxHeight: 128,
    maxWidth: 80
  }


  return (
    <>
      <Row className="pending position-absolute w-100 h-100 m-0 p-0 align-content-center justify-content-center">
        <Row className="w-100 p-0 m-0 justify-content-center">
          <img
            alt="load"
            style={imgStyle}
            src={require("assets/img/brand/Spinner-1s-98px.gif").default}
          />
        </Row>
        <Row className="pt-3 m-0 w-100 justify-content-center">
          <h4 className="load-text">{text}</h4>
        </Row>
      </Row>
    </>
  );
}

export default Loader;
