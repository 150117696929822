export default function getOrganizations() {
    let token = localStorage.getItem("donors-jwt");
    let url = `https://lottery.donors.co.il/wp-json/donors/v1/organizations`;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}

