import React, { useState, useContext } from 'react';
import { Redirect } from "react-router-dom";
//context
import DonorsContext from 'context/DonorsContext';
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import postDonation from 'services/postDonation';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ActionHeader from "components/Headers/ActionHeader.js";
import Pending from "components/custom/Pending";

function AddOffline() {
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState({
    donor: 2,
    amount: '',
    terminal: 'אופליין',
    user: user.team,
    campaign: user.campaign,
    insight: true,
    Comments: '',
    Tashloumim: 999,
    methods_Payment: ''
  });
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { donors, setDonors } = useContext(DonorsContext);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonation();
  }

  function setDonation() {
    setPending(true);
    console.log(formValues);
    postDonation(formValues)
      .then(
        (response) => {
          const { success } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setDonors({
              ...donors,
              ['positive']: donors.positive * 1 + 1,
              ['total']: donors.total * 1 + 1
            });
            setSent(true);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {sent && <Redirect to="/app/home" push={true} />}
      <ActionHeader name="הוספת תרומה" parentName="לתורם: ישראל ישראלי" />
      <Container className="mt--7  pb-5">
        <Row className="justify-content-center" >
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                {pending && <Pending />}
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="methods_Payment"
                      >
                        אמצעי תשלום:
                      </label>
                      <Input
                        id="methods_Payment"
                        type="select"
                        name="methods_Payment"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.methods_Payment}
                      >
                        <option key="1">בחר אמצעי תשלום</option>
                        <option key="2">כרטיס אשראי</option>
                        <option key="3">הוק בנקאי</option>
                        <option key="4">העלאת הוק בנקאי קיים</option>
                        <option key="5">העלאת הוק אשראי קיים</option>
                        <option key="6" >מזומן</option>
                        <option key="7" >אחר</option>
                      </Input>
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="amount"
                          >
                            סכום לחודש
                          </label>
                          <Input
                            id="amount"
                            type="number"
                            name="amount"
                            placeholder="סכום לחודש"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.amount}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="Tashloumim"
                          >
                            תשלומים
                          </label>
                          <Input
                            id="Tashloumim"
                            type="number"
                            name="Tashloumim"
                            placeholder="תשלומים"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.Tashloumim}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Input
                            id="insight"
                            type="checkbox"
                            name="insight"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.insight}
                            required
                          />
                          <br />
                          <label
                            className="form-control-label"
                            htmlFor="insight"
                          >
                            טופס הוק חתום / פרטי אשראי אצלי
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="Comments"
                          >
                            הערות
                          </label>
                          <Input
                            id="Comments"
                            rows="2"
                            type="textarea"
                            name="Comments"
                            placeholder="הערות"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.Comments}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      block
                      color="warning"
                      className="bg-achim-orange"
                      type="submit"
                    >
                      שלח
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddOffline;
