import React, { useState, useContext } from 'react';
import * as XLSX from 'xlsx'
//context
import { AlertMsgContext } from "context/AlertMsgContext";
import { CampaignContext } from "context/campaignContext";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import sendData from 'services/sendData';
import Loader from 'components/custom/Loader';
// core components

function UploadXlsx({ tableName, callget, callgetType }) {
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [readNow, setReadNow] = useState('');
  const { campaign } = useContext(CampaignContext);
  const { showAlert } = useContext(AlertMsgContext);
  const [pending, setPending] = useState(false);

  function handleFile(file /*:File*/) {
    setReadNow('בודק...');
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1
      });
      /* Update state */
      const check = checkData(data);
      if (check) {
        setReadNow(check);
        setData([]);
        setCols([]);
      } else {
        data.shift();
        setData(data)
        setCols(make_cols(ws["!ref"]));
        setReadNow(data.length + ' רשומות');
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  function checkData(array1) {
    let rowLengthNum = 4;
    switch (tableName.table) {
      case 'donors':
        rowLengthNum = 20;
        break;
      case 'donations':
        rowLengthNum = 15;
        break;
      default:
        rowLengthNum = 4;
        break;
    }
    const rowlength = array1.findIndex(row => row.length !== rowLengthNum);
    if (rowlength !== -1) return `ערך חסר / יתר בשורה מס ${rowlength + 1}`;
    else return false;
  }

  function sendRecoreds() {
    setPending(true);
    setReadNow(`מייבא ${tableName.title}...`);
    sendData(campaign.organization, campaign.id, tableName.table, data)
      .then(
        (response) => {
          const { success } = response;
          if (!success) {
            showAlert('הייבוא נכשל', 'danger')
            setReadNow('הייבוא נכשל');
            setData([]);
            setCols([]);
            document.getElementById('file').value = '';
            setPending(false);
            callget(callgetType);
          }
          else {
            callget(callgetType);
            showAlert('הייבוא הסתיים בהצלחה', 'success');
            setReadNow(`${response.data} מתוך ${data.length} ${tableName.title} יובאו בהצלחה`);
            setData([]);
            setCols([]);
            document.getElementById('file').value = '';
            setPending(false);
          }
        })
      .catch((err) => {
        showAlert('שגיאה', 'danger')
        setReadNow('הייבוא נכשל c538');
        console.log(err);
        setData([]);
        setCols([]);
        document.getElementById('file').value = '';
        setPending(false);
      }
      );
  }

  function exportFile() {
    /* convert state to workbook */
    console.log(1);
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  }
  return (
    <Container className="" fluid>
      <Card className="mb-4">
        {pending && <Loader />}
        <CardHeader>
          <h3 className="mb-0">
            ייבוא {tableName.title}
            <a
              href={`https://lottery.donors.co.il/wp-content/uploads/examples/donors-${tableName.title}.xlsx`}
              download
              target="_blank"
              className='text-donors-light font-light'
              rel="noreferrer"
            >
              {' (הורד קובץ לדוגמא)'}
            </a>
          </h3>
        </CardHeader>
        <CardBody>
          <DragDropFile handleFile={handleFile}>
            <div className="row">
              <div className="col-xs-12">
                <DataInput handleFile={handleFile} />
              </div>
            </div>
            {/* <div className="row">
                <div className="col-xs-12">
                  <button
                    disabled={!this.state.data.length}
                    className="btn btn-success"
                    onClick={this.exportFile}
                  >
                    Export
                  </button>
                </div>
              </div> */}
            <div className="row">
              <div className="col-xs-12 mt-2">
                {readNow}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                {data.length > 0 && <button
                  className="btn btn-success"
                  onClick={() => { sendRecoreds() }}
                >
                  ייבוא
                </button>}
              </div>
            </div>
            {/* <div className="row">
                <div className="col-xs-12">
                  <OutTable data={data} cols={cols} />
                </div>
              </div> */}
            {/* <div className="row">
              <div className="col-xs-12">
                <pre>{JSON.stringify(data, null, 1)}</pre>
              </div>
            </div> */}
          </DragDropFile>
        </CardBody>
      </Card>
    </Container>
  );
}

// if (typeof module !== "undefined") module.exports = SheetJSApp;

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group">
          <input
            type="file"
            className="form-control"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {this.props.cols.map((c) => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((r, i) => (
              <tr key={i}>
                {this.props.cols.map((c) => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};


export default UploadXlsx;
