import React, { useState, useContext } from 'react';
//context
import { AlertMsgContext } from "context/AlertMsgContext";
import { CampaignContext } from "context/campaignContext";
//services
import updateDonation from 'services/updateDonation';
import deleteDonation from 'services/deleteDonation';
//context
import { UserContext } from "context/userContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function EditDonation({ donation, modalCallback, callget }) {
  const { id, name, donor, amount, terminal, user, Comments, Tashloumim, methods_Payment, KevaId } = donation
  const { user: currentUser } = useContext(UserContext);
  const { campaign } = useContext(CampaignContext);
  const { id: campaignId, campaign_type } = campaign;
  const [formValues, setFormValues] = useState({
    id: id,
    donor: donor,
    amount: amount,
    terminal: terminal,
    user: user,
    campaign: campaignId,
    insight: true,
    Comments: Comments,
    Tashloumim: Tashloumim,
    methods_Payment: methods_Payment,
    approved: currentUser.type == 2 ? 1 : 0,
    KevaId, KevaId
  });
  const [pending, setPending] = useState(false);
  const { showAlert } = useContext(AlertMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setDonation();
  }

  function removeDonation() {
    setPending(true);
    deleteDonation(formValues.id)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            callget(4000, 0);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function setDonation() {
    setPending(true);
    updateDonation(formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            showAlert('העדכון נכשל', 'danger')
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
            callget(4000, 0);
          }
        })
      .catch((err) => {
        showAlert('העדכון נכשל c', 'danger')
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      {currentUser.permission == 3 ?
        <div>
          <h1 className="text-center m-4">עריכת תרומה</h1>
          <h4 className="text-center m-4">אין לך הרשאה</h4>
        </div> :
        <div>
          <CardHeader className="text-center">
            <h1>עריכת תרומה מס' {id}</h1>
            <h3>לתורם {name} ({donor})</h3>
          </CardHeader>
          <CardBody>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                  htmlFor="methods_Payment"
                >
                  אמצעי תשלום:
                </label>
                <Input
                  id="methods_Payment"
                  type="select"
                  name="methods_Payment"
                  onChange={handleChange}
                  onBlur={handleChange}
                  required
                  value={formValues.methods_Payment}
                >
                  {campaign_type === 'HK' ?
                    <>
                      <option key="2">אשראי</option>
                      <option key="3">הוק בנקאי</option>
                      <option key="4">העלאת הוק בנקאי קיים</option>
                      <option key="5">העלאת הוק אשראי קיים</option>
                      <option key="6" >מזומן</option>
                      <option key="7" >אחר</option>
                      <option key="8" >nedarim</option>
                    </> :
                    <>
                      <option key="2">מזומן</option>
                      <option key="3">שיק</option>
                      <option key="4">העברה בנקאית</option>
                      <option key="5">אשראי</option>
                      <option key="6">אשראי במסופון</option>
                      <option key="7" >אשראי קיים</option>
                      <option key="8" >nedarim</option>
                    </>
                  }
                </Input>
              </FormGroup>
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="amount"
                    >
                      {campaign_type === 'HK' ? 'סכום לחודש' : 'סך התרומה'}
                    </label>
                    <Input
                      id="amount"
                      type="number"
                      name="amount"
                      placeholder={campaign_type === 'HK' ? 'סכום לחודש' : 'סך התרומה'}
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.amount}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="Tashloumim"
                    >
                      תשלומים
                    </label>
                    <Input
                      id="Tashloumim"
                      type="number"
                      name="Tashloumim"
                      placeholder="תשלומים"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.Tashloumim}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="KevaId"
                    >
                      כרטיסים
                    </label>
                    <Input
                      id="KevaId"
                      type="number"
                      name="KevaId"
                      placeholder="כרטיסים"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.KevaId}
                    />
                  </FormGroup>
                </Col>
                {/*  <Col md="6">
                  <FormGroup className="mb-3">
                    <Input
                      id="insight"
                      type="checkbox"
                      name="insight"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.insight}
                      checked={formValues.insight}
                      required
                    />
                    <br />
                    <label
                      className="form-control-label"
                      htmlFor="insight"
                    >
                      טופס הוק חתום / פרטי אשראי אצלי
                    </label>
                  </FormGroup>
                </Col> */}
                <Col md="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="Comments"
                    >
                      הערות
                    </label>
                    <Input
                      id="Comments"
                      rows="2"
                      type="textarea"
                      name="Comments"
                      placeholder="פרטי התרומה"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={formValues.Comments}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                block
                color="info"
                className="bg-info"
                type="submit"
              >
                שמור תרומה
              </Button>
            </Form>
            {currentUser.campaign.permission < 5 && <><Row>
              <Col sm={6} className="mt-3">
                <Button
                  size="sm"
                  block
                  color="warning"
                  onClick={removeDonation}
                >
                  מחק תרומה
                </Button>
              </Col>
            </Row>
              <small className="text-red">זהירות, לא ניתן לשחזר את התרומה, לאחר לחיצה על כפתור זה!</small>
            </>
            }
          </CardBody>
        </div>}
    </>
  );
}

export default EditDonation;
