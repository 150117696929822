import { createContext, useEffect, useState } from 'react';

export const UserContext = createContext();

const UserContextProvider = (props) => {

    const { tokenUrl, dataUrl, headers } = props;
    const [token, setToken] = useState(localStorage.getItem('donors-jwt'));
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState(false);
    const options = { credentials: 'include', mode: 'cors', headers };

    useEffect(async () => {
        if (token) {
            headers.set('Authorization', 'Bearer ' + token);
            await fetch(tokenUrl + 'validate', { ...options, method: 'POST', body: JSON.stringify(token) })
                .then(res => res.json())
                .then(res => {
                    if (res.data.status == 200) {
                        return;
                    } else throw new Error('token is invalid')
                })
                .then(() => fetch(dataUrl + 'current-user', options))
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        function deleteAllCookies() {
                            var cookies = document.cookie.split(";");
                            console.log(cookies);
                            for (var i = 0; i < cookies.length; i++) {
                                var cookie = cookies[i];
                                var eqPos = cookie.indexOf("=");
                                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                            }
                        }

                        deleteAllCookies();
                        let cookies = document.cookie.split(";");
                        console.log(cookies);

                        return res.data
                    }
                    else throw new Error("couldn't fetch current user")
                })
                .then(data => {
                    setUser(data)
                })
                .catch(err => console.log(err))
        }
        setIsLoading(false)
    }, [token])

    useEffect(() => {
        if (user) {
            setUserRole(user.caps);
        }
        else setUserRole(false)
    }, [user])

    return (
        <UserContext.Provider value={{ user, isLoading, token, setToken, setUser, tokenUrl, dataUrl, headers, userRole }} >
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;