import React, { useContext, useEffect, useState } from 'react';
//context
import { UserContext } from "context/userContext";
import { CampaignContext } from "context/campaignContext";
import { AlertMsgContext } from "context/AlertMsgContext";
//services
import getUser from 'services/getUser';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  CardTitle,
  CardHeader,
  Table,
  Progress
} from "reactstrap";
//
import Summary1 from "views/pages/tables/Summary1.js";
import Summary2 from "views/pages/tables/Summary2.js";
import ExportDonations from "views/pages/forms/ExportDonations.js";
import Loader from "components/custom/Loader";



function CardsHeaderAchim() {
  const { campaign, setCampaign } = useContext(CampaignContext);
  const { user, setUser } = useContext(UserContext);
  const { showAlert } = useContext(AlertMsgContext)
  const [summary, setSummary] = useState({});
  const [pending, setPending] = useState(false);


  function callgetHours() {
    setPending(true);
    getUser(user.ID, user.campaign.id, user.role)
      .then(
        (response) => {
          setCampaign({
            ...response[0].data[0]
          });
          setSummary(
            response[1]
          );
          setPending(false);
        })
      .catch((err) => {
        console.log('Error! c', err);
        showAlert("שגיאה", 'danger')
        setPending(false);

      }
      );
  }

  function calculatePercentage(goal, total) {
    return ((total / goal) * 100).toFixed(0) + '%';
  }
  function calculatePercentageNum(goal, total) {
    return ((total / goal) * 100).toFixed(0);
  }

  function separator(numb) {
    if (!numb) return numb;
    let str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }

  useEffect(() => {
    document.title = `Lottors - דשבורד`;
    callgetHours();
  }, [])

  const roles = ['administrator', 'manager', 'agent', 'leader'];

  return (
    <>
      <div className="header pb-2 pt-1">
        <Container fluid>
          <div>
            <h2 className="text-center mt-3">{campaign.campaign} {user.role > 3 && " - " + user.displayName}</h2>
          </div>
          <Container fluid className='text-left mt--5 mb-3' >
            <Button
              className="m-2"
              color="default"
              size="md"
              id=""
              onClick={() =>
                window.open(`https://lottery.donors.co.il/cmp/${campaign.id}/`, '_blank')
              }
            >
              <span>דף התרמה {'>'}</span>
            </Button>
          </Container>
          <div className="header-body">
            <Row className="mt-4 align-items-stretch">
              {pending && <Loader text="טוען" />}
              {roles.includes(user.campaign.role) && user.campaign.permission < 25 ?
                <>
                  <Col md="6" xl="4">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-achim-pink mb-0"
                            >
                              תרומות
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                              {separator(campaign.total)} ₪ (לחודש)
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-achim-pink text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mb-0 text-md font-weight-600">
                          {0} ₪ <span className="h4 font-weight-300">ללא הגבלה לפי 24 חוד'</span>
                          < br />
                          {calculatePercentage(campaign.goal, campaign.total)} מהיעד <span className="font-weight-400">({separator(campaign.goal)} ₪ לחודש)</span>
                        </p>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value={calculatePercentageNum(campaign.goal, campaign.total)}
                          color="success"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-blue mb-0"
                            >
                              תורמים
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                              {separator(campaign.donors)} תורמים
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                              <i className="ni ni-single-02" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mb-0 text-md font-weight-600">
                          <span className="font-weight-400">({campaign.donations} תרומות)</span>
                          < br />
                          {calculatePercentage(campaign.donorsGoal, campaign.donors)} מהיעד <span className="font-weight-400">({separator(campaign.donorsGoal)} תורמים)</span>
                        </p>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value={calculatePercentageNum(campaign.donorsGoal, campaign.donors)}
                          color="success"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-info mb-0"
                            >
                              כרטיסים
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                              {separator(campaign.tickets)} כרטיסים
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="ni ni-credit-card" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mb-0 text-md font-weight-600">
                          <span className="font-weight-400">(כולל כרטיסים מוכפלים)</span>
                          < br />
                          {calculatePercentage(5000, campaign.tickets)} מהיעד <span className="font-weight-400">({separator(5000)} כרטיסים)</span>
                        </p>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value={calculatePercentageNum(5000, campaign.tickets)}
                          color="success"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </>
                :
                <>
                  <Col md="6" xl="4">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-muted mb-0"
                            >
                              יעד תרומות - {user.team}
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                              {separator(user.total)} ₪
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-md font-weight-600">
                          {calculatePercentage(user.goal, user.total) === "NaN%" ? "0%" : calculatePercentage(user.goal, user.total)} מהיעד <span className="font-weight-400">({separator(user.goal)} ₪)</span>
                        </p>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value={calculatePercentageNum(user.goal, user.total)}
                          color="success"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h4"
                              className="text-uppercase text-muted mb-0"
                            >
                              יעד תורמים - {user.team}
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                              {separator(user.donors)} תורמים
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                              <i className="ni ni-single-02" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-md font-weight-600">
                          {calculatePercentage(user.donorsGoal, user.donors) === "NaN%" ? "0%" : calculatePercentage(user.donorsGoal, user.donors)} מהיעד <span className="font-weight-400">({separator(user.donorsGoal)} תורמים)</span>
                        </p>
                        <Progress
                          className="progress-xs my-2"
                          max="100"
                          value={calculatePercentageNum(user.donorsGoal, user.donors)}
                          color="success"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
            </Row>
            {(roles.includes(user.campaign.role) && user.campaign.permission < 25 && summary) &&
              <>
                <Row>
                  <Col xl="6">
                    <Summary1/>
                  </Col>
                  <Col xl="6">
                    <Summary2 summary2={summary} />
                  </Col>
                </Row>
              </>}

          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeaderAchim.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeaderAchim;
