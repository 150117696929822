import React, { useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from "context/userContext";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Card,
  Row,
  Col,
  CardHeader,
  Table,
} from "reactstrap";
import Loader from "components/custom/Loader";



function Summary1() {
  const { user } = useContext(UserContext);

  function getSummaryPackages() {

    return user.campaign.packages.map((option) => (
      <tr key={option.id}>
        <td>{option.name}</td>
        <th>{option.total -1}</th>
        <td>{option.price}</td>
        <td>{option.tickets}</td>
        <td>{option.gift}</td>
      </tr>
    ))
  }

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">חבילות</h3>
            </div>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">החבילה</th>
              <th scope="col">כמות</th>
              <th scope="col">מחיר</th>
              <th scope="col">כרטיסים</th>
              <th scope="col">מתנה</th>
            </tr>
          </thead>
          <tbody>
            {user.campaign.packages.length > 0 || <Loader text="" />}
            {user.campaign.packages.length > 0 && getSummaryPackages()}
          </tbody>
        </Table>
      </Card>
    </>
  );
}

export default Summary1;
