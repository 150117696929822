export default function getDonorDonations(donor) {
    let url = `https://lottery.donors.co.il/wp-json/shpro/v1/donor/${donor}/donations`;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}


