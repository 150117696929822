import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AlertMsgContextProvider from "context/AlertMsgContext";
import UserContextProvider from "context/userContext";
import CampaignContextProvider from "context/campaignContext";

import App from 'App';

/* app.use(express.static(__dirname));

app.get("/*", function(req, res) {
  res.sendFile(path.join(__dirname, "index.html"));
}); */

const tokenUrl = 'https://lottery.donors.co.il/wp-json/jwt-auth/v1/token/'
const dataUrl = 'https://lottery.donors.co.il/wp-json/donors/v1/'
let headers = new Headers()
headers.set('Accept', 'application/json');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AlertMsgContextProvider>
        <UserContextProvider {...{ tokenUrl, dataUrl, headers }} >
        <CampaignContextProvider {...{ dataUrl, headers }} >
          <App />
        </CampaignContextProvider>
        </UserContextProvider>
      </AlertMsgContextProvider>
    </BrowserRouter>
  </React.StrictMode >,
  document.getElementById('root')
);